/**
 * Import default package
 *
 * @format
 */

/** Import from third party */
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../../../assets/css/ladiui.gallery.css';
import ConfirmModal from '../../../../components/ConfirmModal';
/** Import component from my app */
import Input from '../../../../components/Input';
import LoadingScene from '../../../../components/LoadingScene';
import Modal from '../../../../components/Modal';
/** Import redux */

import productImageActions from '../../../../redux/futures/product_image/actions';
import * as productImageTypes from '../../../../redux/futures/product_image/types';

import productTagActions from '../../../../redux/futures/product_tag/actions';
import * as productTagTypes from '../../../../redux/futures/product_tag/types';

import fileActions from '../../../../redux/futures/file/actions';
import * as fileTypes from '../../../../redux/futures/file/types';

import productActions from '../../../../redux/futures/product/actions';
import * as productTypes from '../../../../redux/futures/product/types';
import Tag from '../../../../components/Tag';

import courseActions from '../../../../redux/futures/course/actions';

import appConfig from '../../../../config/app';
import NumberInput from '../../../../components/NumberInput';

import _, { remove, cloneDeep, map, compact, includes, pick, find } from 'lodash';
import SelectOption from '../../../../components/SelectOption';
import baseHelper from '../../../../helpers/BaseHelper';
import produce from 'immer';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import PanelProductSellSocial from '../../../../components/PanelProductSeoSocial';
import ValidatorHelper from '../../../../helpers/ValidatorHelper';
import PanelProductContentCourse from '../../../../components/PanelContentProductCourse';

import * as productCategoryTypes from '../../../../redux/futures/product_category/types';
import productCategoryActions from '../../../../redux/futures/product_category/actions';

import storePageActions from '../../../../redux/futures/store_page/actions';
import * as storePageTypes from '../../../../redux/futures/store_page/types';

import ModalAddProduct from '../../../orders/OrderCreate/components/ModalAddProduct';

import DatePicker from '../../../../components/DatePicker/CustomDatePicker';

import SelectManyDropdown from '../../../../components/SelectManyDropdown';
import SelectManyDropdownStore from '../../../../components/SelectManyDropdownStore';

import PanelListStudent from '../PanelListStudent';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import moment from 'moment';

const CDN = 'https://w.ladicdn.com/';

class ModalEditCourse extends React.Component {
    static propTypes = {
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        visible: PropTypes.bool,
        loading: PropTypes.bool,
        openVariantID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    };

    constructor(props) {
        super(props);

        this.moment = baseHelper.getMoment();

        this.state = {
            isCopy: false,
            isReloadIframe: false,
            categories: [],
            product_category_ids: [],
            stores: [],
            selected_store_ids: [],
            product: {
                product_id: '',
                product_variant_id: '',
                name: '',
                price: '',
                sku: '',
                hosted_by: '',
                alias_name: '',
                thumbnail_url: '',
                description: '',
                total_student: 0,
                type: appConfig.PRODUCT_TYPE_CONFIG.PHYSICAL.CODE,
                checkout_config_id: '',
                status: appConfig.PRODUCT_STATUS.INACTIVE.CODE,
                course_benefits: [],
                short_description: '',
                outstanding_features: [],
                seo: {},
                is_one_time: false,
                // start_date: new Date(),
            },
            seo_social: {
                title_page: '',
                description_page: '',
                img_publish_page: '',
                favicon_page: '',
            },
            images: [],
            variants: [],
            tags: [],
            tabIndexActive: 0,
            course_modules: [],
            selectedTabIndex: 1,
            // isShowModalAddProduct: false,
        };

        this.selectedImage = {};

        this.ref = React.createRef();
        this.fileInputRef = React.createRef();
        this.panelEditVariantRef = React.createRef();

        this.inputsRef = new Set();
        this.tagRef = React.createRef();
        this.productSeoSocialRef = React.createRef();
        this.panelProductContentCourseRef = React.createRef();

        // this.PanelProductSellSocialRef = React.createRef();
    }

    componentDidMount() {
        let paramUrl = queryString.parse(this.props.location.search);
        this.props.show(this.props.match.params['productID']);
        if (!paramUrl.is_copy) {
            this.props.moduleList({ product_id: this.props.match.params['productID'] });
        }

        if (this.props.openVariantID) {
            setTimeout(() => {
                document.getElementById('trigger-tab-variants-edit').click();
            }, 100);
        }

        const { tab } = queryString.parse(this.props.location.search);
        if (tab == 'checkout') {
            setTimeout(() => {
                document.getElementById('trigger-tab-checkout').click();
            });
            this.props.history.push(`/courses`);
        }

        if (paramUrl.is_copy) {
            this.setState({
                isCopy: true,
            });
        }

        this.props.listAllTags();

        let payload = {
            search: {
                type: 'Course',
            },
        };

        const dataListStore = {
            keyword: '',
            search: {},
            paged: 1,
            limit: 20,
        };

        this.props.listStores(dataListStore);
        this.props.listCategorySelect(payload);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.fileReducer.action != nextProps.fileReducer.action) {
            if (nextProps.fileReducer.action === fileTypes.UPLOAD_PRODUCT_IMAGES) {
                if (nextProps.fileReducer.status) {
                    let { images, isCopy } = this.state;
                    const newUploadedImage = map(nextProps.fileReducer.product_images, (item, index) => {
                        return {
                            src: item,
                            position: images.length + index,
                        };
                    });

                    if (!isCopy) {
                        const productImages = {
                            product_id: this.state.product.product_id,
                            images: newUploadedImage,
                        };
                        this.props.createImages(productImages);
                    } else {
                        this.setState({
                            images: newUploadedImage,
                        });
                    }

                    // this.setState({
                    //   product: {
                    //     ...this.state.product,
                    //     thumbnail_url: nextProps.fileReducer.product_images[0],
                    //   },
                    // });
                }
            }
        }
        if (this.props.productImage.action != nextProps.productImage.action) {
            if (nextProps.productImage.action === productImageTypes.PRODUCT_IMAGE_CREATE) {
                if (nextProps.productImage.status) {
                    this.setState(
                        {
                            images: nextProps.productImage.images,
                        },
                        () => this.renewGallery()
                    );
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.productImage.message, 'OK');
                }
            }
        }
        if (this.props.productCategory.action != nextProps.productCategory.action) {
            if (nextProps.productCategory.action === productCategoryTypes.LIST_SELECT_CATEGORY) {
                if (nextProps.productCategory.status) {
                    let _categories = nextProps.productCategory.listSelects;
                    let _data = this.state.categories || [];
                    _categories.map((item, index) => {
                        const isExitCategory = _data.some((_item) => _item.value == item.product_category_id);
                        if (!isExitCategory) {
                            _data.push({
                                level: item.level,
                                name: item.name,
                                value: item.product_category_id,
                            });
                        }
                    });
                    this.setState({
                        categories: _data,
                    });
                }
            }
        }
        if (nextProps.product.action === productTypes.SHOW_PRODUCT) {
            if (nextProps.product.status) {
                let normalizeCopyProduct = { ...nextProps.product.product };
                if (this.state.isCopy) {
                    this.initCreate(normalizeCopyProduct);
                } else {
                    this.initUpdate(normalizeCopyProduct);
                }
            } else {
                // Không show lôi, thay vào đó redirect
                this.props.history.push('/courses');
            }
        }

        if (nextProps.product.action === productTypes.UPDATE_PRODUCT) {
            const { isReloadIframe } = this.state;

            this.setState({
                isReloadIframe: !isReloadIframe,
            });
            if (nextProps.product.status) {
                window.LadiUI.toastCustom('success', '', nextProps.product.message);
                this.props.reloadProduct(this.state.product.product_id);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', nextProps.product.message, 'OK');
            }
        }

        if (nextProps.product.action === productTypes.CREATE_PRODUCT) {
            const { isReloadIframe } = this.state;

            this.setState({
                isReloadIframe: !isReloadIframe,
            });
            if (nextProps.product.status) {
                window.LadiUI.toastCustom('success', '', nextProps.product.message);
                this.props.history.push(`/courses`);
                // this.props.reloadProduct(this.state.product.product_id);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', nextProps.product.message, 'OK');
            }
        }

        if (nextProps.product.action === productTypes.RELOAD_PRODUCT) {
            if (nextProps.product.status) {
                this.initUpdate(nextProps.product.product);
            }
        }

        if (nextProps.course.action == 'COURSE_MODULE_LIST') {
            if (nextProps.course.status) {
                if (nextProps.course.courses && nextProps.course.courses.length > 0) {
                    this.setState({
                        course_modules: nextProps.course.courses,
                    });
                }
            }
        }

        if (this.props.storePage.action != nextProps.storePage.action) {
            if (nextProps.storePage.action == storePageTypes.STORE_PAGE_LIST) {
                if (nextProps.storePage.status) {
                    if (nextProps.storePage.store_pages && nextProps.storePage.store_pages.length > 0) {
                        let _data = [];
                        nextProps.storePage.store_pages.map((item, index) => {
                            _data.push({
                                name: item.name,
                                value: item.page_checkout_id,
                            });
                        });

                        this.setState({
                            stores: _data,
                        });
                    }
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.storePage.message, 'OK');
                }
            }
        }
    }

    initCreate = async (props) => {
        let currentProductInfo = cloneDeep(props);
        currentProductInfo = await baseHelper.normalizeCopyProductCourse(currentProductInfo);

        const customFields = currentProductInfo.custom_fields || [];
        delete currentProductInfo.path;
        delete currentProductInfo.url_published;
        let { seo } = currentProductInfo;
        let product = { ...currentProductInfo };

        if (currentProductInfo.interface_options) {
            product.interface_options = JSON.parse(currentProductInfo.interface_options);
        } else product.interface_options = [];

        let newProduct = {};

        map(this.state.product, (value, key) => {
            newProduct[key] = !baseHelper.isEmpty(product[key]) ? product[key] : '';
            if (key == 'min_buy' && baseHelper.isEmpty(product[key])) {
                newProduct[key] = 1;
            }
            if (key == 'seo' && baseHelper.isEmpty(product[key])) {
                newProduct['seo'] = {
                    title: '',
                    description: '',
                    keywords: '',
                    image: '',
                    canonical_url: '',
                };
            }
        });

        const newCustomFields = !baseHelper.isEmpty(customFields) ? customFields : [];

        this.setState({
            product: newProduct,
            images: currentProductInfo.images,
            variants: currentProductInfo.variants,
            product_category_ids: currentProductInfo.product_category_ids,
            selected_store_ids: currentProductInfo.page_checkout_ids,
            tags: currentProductInfo.tags,
            options: currentProductInfo.options,
            customFields: newCustomFields,
        });
    };

    getIconCTA = (code) => {
        const matched = find(appConfig.LIST_CTA, (item) => item.CODE == code) || {};
        if (matched) {
            return matched.CLASS_ICON;
        }
        return 'cta-add';
    };

    initUpdate = (props) => {
        const currentProductInfo = cloneDeep(props);

        let { tags, images } = currentProductInfo;

        let product = { ...currentProductInfo };
        let variants = currentProductInfo.variants || [];

        delete currentProductInfo.variants;
        delete currentProductInfo.tags;
        delete currentProductInfo.images;

        if (currentProductInfo.interface_options) {
            product.interface_options = JSON.parse(currentProductInfo.interface_options);
        } else product.interface_options = [];

        if (currentProductInfo.product_category_ids && currentProductInfo.product_category_ids.length > 0) {
            this.setState({
                product_category_ids: currentProductInfo.product_category_ids,
            });
        }

        if (currentProductInfo.page_checkout_ids && currentProductInfo.page_checkout_ids.length > 0) {
            this.setState({
                selected_store_ids: currentProductInfo.page_checkout_ids,
            });
        }

        let newProduct = {};
        map(this.state.product, (value, key) => {
            newProduct[key] = !baseHelper.isEmpty(product[key]) ? product[key] : '';
            if (key == 'path' && baseHelper.isEmpty(newProduct[key])) {
                newProduct[key] = '' + product.product_id;
            }
            if (key == 'seo' && baseHelper.isEmpty(product[key])) {
                newProduct['seo'] = {
                    title: '',
                    description: '',
                    keywords: '',
                    image: '',
                    canonical_url: '',
                };
            }
        });

        newProduct = {
            ...newProduct,
            ...pick(variants[0], ['product_variant_id', 'price', 'sku']),
        };

        variants.length = 0;

        const newTags = tags || [];
        const newImages = images || [];

        this.setState(
            {
                product: newProduct,
                tags: newTags,
                images: newImages,
            },
            () => this.renewGallery()
        );
    };

    renewGallery = () => {
        if (document.getElementById('gallery-edit')) {
            window.renewGallery(['gallery-edit'], 0);
        }
    };

    /*******************************HANDLE INPUT***************************/
    onChangeProductInput = (event) => {
        this.setState({
            product: {
                ...this.state.product,
                [event.target.name]: event.target.value,
                alias_name: event.target.name == 'name' ? baseHelper.getAliasName(event.target.value) : this.state.product.alias_name,
            },
        });
    };

    handleEditorChange = (value) => {
        this.setState({
            product: {
                ...this.state.product,
                description: value,
            },
        });
    };

    onChangeInput = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    };
    /*****************************************************************/

    /******************************IMAGE*********************************/
    fileChangedHandler = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                this.props.uploadProductImages(form);
            }
        }
    };
    /*****************************************************************/

    getVariantReq = (variants) => {
        const { product_id, product_variant_id, price, sku } = this.state.product;

        if (variants.length <= 0) {
            const defaultVariant = {
                product_id,
                product_variant_id,
                price,
                sku,
            };
            variants.push(defaultVariant);
        }

        return variants;
    };

    submit = () => {
        // Validate
        let errors = [];
        let flagFocusTab = true;
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    if (flagFocusTab) {
                        const tabActiveID = ReactDOM.findDOMNode(ref.ref.current).closest('.ladiui.tab-pane').getAttribute('id');
                        document.getElementById(`trigger-${tabActiveID}`).click();
                    }
                    flagFocusTab = false;
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        // Tao productRequest
        const { product, images, product_category_ids, stores, selected_store_ids } = this.state;

        let variants = this.state.variants || [];

        let createData = {};
        let options = [];

        const tags = this.tagRef.current.getData();

        const productSeoSocial = this.productSeoSocialRef.current.getData();

        if (productSeoSocial) {
            if (productSeoSocial.title && productSeoSocial.title.length > 70) {
                window.LadiUI.toastCustom('warning', '', 'Tiêu đề vượt quá số ký tự quy định!');
                return;
            }
            if (productSeoSocial.description && productSeoSocial.description.length > 170) {
                window.LadiUI.toastCustom('warning', '', 'Mô tả vượt quá số ký tự quy định!');
                return;
            }
            if (productSeoSocial.keywords && productSeoSocial.keywords.length > 170) {
                window.LadiUI.toastCustom('warning', '', 'Từ khóa vượt quá số ký tự quy định!');
                return;
            }
        }

        variants = this.getVariantReq(variants);

        let productReq = {
            ...product,
            is_show_all_store: selected_store_ids.length == stores.length ? 1 : 0,
            page_checkout_ids: selected_store_ids || [],
            product_category_ids: product_category_ids,
            interface_options: JSON.stringify(product.interface_options),
            tags,
            images,
            options,
            variants,
            seo: { ...productSeoSocial },
        };

        if (this.state.isCopy) {
            this.props.create(productReq);
        } else {
            this.props.update(productReq);
        }
    };

    listItemCourseBenefits = () => {
        let listBenefits = [...this.state.product.course_benefits];
        if (listBenefits && listBenefits.length > 0) {
            return listBenefits.map((item, index) => {
                return (
                    <Draggable key={index} draggableId={'' + index} index={index}>
                        {(provided, snapshot) => (
                            <div
                                className='item-benefit flex mt-24 items-center'
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                    ...provided.draggableProps.style,
                                    userSelect: 'none',
                                    display: 'table-row',
                                }}
                            >
                                <i className='ldicon-drag-drop mr-8' />
                                <div className='ladiui form-group m-0'>
                                    <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        name='content'
                                        placeholder='Nhập lợi ích khóa học'
                                        value={item.content}
                                        onChange={(event) => this.onChangeInputCourseBenefit(event, item)}
                                    // validations={{ isRequired: true }}
                                    />
                                </div>
                                <div className='remove-item-benefit ml-8' onClick={() => this.deleteCourseBenefit(item)}>
                                    <a>
                                        <i className='ladi-icon icon-bin'></i>
                                    </a>
                                </div>
                            </div>
                        )}
                    </Draggable>
                );
            });
        }
    };

    addCourseBenefit = () => {
        let listBenefits = [...this.state.product.course_benefits];
        listBenefits.push({
            position: listBenefits.length + 1,
            content: '',
        });
        this.setState({
            product: {
                ...this.state.product,
                course_benefits: listBenefits,
            },
        });
    };

    onChangeInputCourseBenefit = (event, item) => {
        let { value } = event.target;
        let { course_benefits } = this.state.product;
        let courseBenefitsChange = produce(course_benefits, (draft) => {
            map(draft, (_draft) => {
                if (_draft.position == item.position) {
                    _draft.content = value;
                }
            });
        });

        this.setState({
            product: {
                ...this.state.product,
                course_benefits: courseBenefitsChange,
            },
        });
    };

    onChangeInputOutstanding = (event, item) => {
        let { value } = event.target;
        let { outstanding_features } = this.state.product;
        let outstandingFeatureChange = produce(outstanding_features, (draft) => {
            map(draft, (_draft) => {
                if (_draft.position == item.position) {
                    _draft.content = value;
                }
            });
        });

        this.setState({
            product: {
                ...this.state.product,
                outstanding_features: outstandingFeatureChange,
            },
        });
    };

    deleteCourseBenefit = (item) => {
        let listBenefits = [...this.state.product.course_benefits];
        let listItemsDelete = listBenefits.filter((data) => data.position != item.position);
        if (listItemsDelete) {
            this.setState({
                product: {
                    ...this.state.product,
                    course_benefits: listItemsDelete,
                },
            });
        }
    };

    deleteOutstanding = (item) => {
        let listOutstandingFeatures = [...this.state.product.outstanding_features];
        let listItemsDelete = listOutstandingFeatures.filter((data) => data.position != item.position);
        if (listItemsDelete) {
            this.setState({
                product: {
                    ...this.state.product,
                    outstanding_features: listItemsDelete,
                },
            });
        }
    };

    listOutstandingFeatures = () => {
        let _listOutstandingFeatures = [...this.state.product.outstanding_features];
        if (_listOutstandingFeatures && _listOutstandingFeatures.length > 0) {
            return _listOutstandingFeatures.map((item, index) => {
                return (
                    <Draggable key={index} draggableId={'' + index} index={index}>
                        {(provided, snapshot) => (
                            <div
                                className='item-requirement flex mt-24 items-center'
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                    ...provided.draggableProps.style,
                                    userSelect: 'none',
                                    display: 'table-row',
                                }}
                            >
                                <i className='ldicon-drag-drop mr-8' />
                                <div className='ladiui form-group m-0'>
                                    <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        name='content'
                                        placeholder='Nhập đặc điểm nổi bật'
                                        value={item.content}
                                        onChange={(event) => this.onChangeInputOutstanding(event, item)}
                                    // validations={{ isRequired: true }}
                                    />
                                </div>
                                <div className='remove-item-benefit ml-8' onClick={() => this.deleteOutstanding(item)}>
                                    <a>
                                        <i className='ladi-icon icon-bin'></i>
                                    </a>
                                </div>
                            </div>
                        )}
                    </Draggable>
                );
            });
        }
    };

    addOutstandingFeatures = () => {
        let _listOutstandingFeatures = [...this.state.product.outstanding_features];
        _listOutstandingFeatures.push({
            position: _listOutstandingFeatures.length + 1,
            content: '',
        });
        this.setState({
            product: {
                ...this.state.product,
                outstanding_features: _listOutstandingFeatures,
            },
        });
    };

    onDragEndCourseBenefit = (result) => {
        const { course_benefits } = this.state.product;
        // dropped outside the list
        if (!result.destination || result.source.index == result.destination.index) {
            return;
        }

        const newVariants = produce(course_benefits, (draft) => {
            const [removed] = draft.splice(result.source.index, 1);
            draft.splice(result.destination.index, 0, removed);

            Object.values(draft).forEach((item, index) => {
                item.position = index;
            });
        });

        this.setState({
            product: {
                ...this.state.product,
                course_benefits: newVariants,
            },
        });
    };

    onDragEndOutstandingFeatures = (result) => {
        const { outstanding_features } = this.state.product;
        // dropped outside the list
        if (!result.destination || result.source.index == result.destination.index) {
            return;
        }

        const newVariants = produce(outstanding_features, (draft) => {
            const [removed] = draft.splice(result.source.index, 1);
            draft.splice(result.destination.index, 0, removed);

            Object.values(draft).forEach((item, index) => {
                item.position = index;
            });
        });

        this.setState({
            product: {
                ...this.state.product,
                outstanding_features: newVariants,
            },
        });
    };

    // onChangeDateTime = (value) => {
    //   this.setState({
    //     product: {
    //       ...this.state.product,
    //       start_date: value,
    //     },
    //   });
    // };

    handleRefreshDataCategories = () => {
        let payload = {
            search: {
                type: 'Course',
            },
        };
        this.props.listCategorySelect(payload);
    };
    setSelectedTabIndex = (index) => {
        if (this.setState.isCopy && (index == 2 || index == 3)) {
            window.LadiUI.toastCustom('warning', '', 'Vui lòng bấm nút "Tạo mới khóa học" để thực hiện hành động này!');
            return;
        }
        this.setState({
            selectedTabIndex: index,
        });
    };

    // handleSearchData = (value) => {
    //     let payload = {
    //         search: {
    //             type: "Course",
    //             keyword: value
    //         }
    //     }
    //     this.props.listCategorySelect(payload);
    // }

    render() {
        const { t } = this.props;
        const {
            product,
            seo_social,
            variants,
            tags,
            isCopy,
            images,
            productUpSells,
            customFields,
            isReloadIframe,
            categories,
            product_category_ids,
            stores,
            selected_store_ids,
            selectedTabIndex,
        } = this.state;

        const { currency_symbol: currencySymbol, currency_code: currencyCode } = this.props.store.userInfo.currentStore;

        const hasNoVariant = variants.length == 0;
        const isLoadingDelete =
            this.props.productImage.loading && includes([productImageTypes.PRODUCT_IMAGE_DELETE], this.props.productImage.waiting);
        const isLoadingImage =
            this.props.productImage.loading && includes([productImageTypes.PRODUCT_IMAGE_DELETE], this.props.productImage.waiting);
        // const isLoadingUpload =
        //   this.props.fileReducer.loading &&
        //   this.props.fileReducer.waiting == fileTypes.UPLOAD_PRODUCT_IMAGES;

        const isSubmitLoading =
            this.props.product.loading && includes([productTypes.CREATE_PRODUCT, productTypes.UPDATE_PRODUCT], this.props.product.waiting);
        const isLoadingHomePageCreate = this.props.product.loading && this.props.product.waiting == productTypes.CREATE_PRODUCT;
        const isLoadingHomePageUpdate = this.props.product.loading && this.props.product.waiting == productTypes.UPDATE_PRODUCT;

        const allTags = this.props.productTag.allTags || [];

        return (
            <div className='page-course-edit' id='page-course-edit'>
                {(isLoadingImage || isSubmitLoading) && <LoadingScene blur={true} />}
                <div className='menu-header'>
                    <div className='content'>
                        <div className='title-action'>
                            <h3>{isCopy ? t('COURSES.ADD_COURSE') : t('COURSES.COURSE_UPDATE')}</h3>
                            {this.state.tabIndexActive != 2 && (
                                <div className='flex'>
                                    <button
                                        onClick={(e) => this.props.history.push('/courses')}
                                        className='ladiui btn btn-secondary mr-24 '
                                    >
                                        {t('ACTIONS.REMOVE')}
                                    </button>

                                    <button
                                        type='button'
                                        className={`ladiui btn btn-primary btn-lg btn-open-modal ${isSubmitLoading ? 'loader' : ''}`}
                                        disabled={isSubmitLoading}
                                        onClick={this.submit}
                                    >
                                        <div>{isCopy ? 'Tạo mới khóa học' : 'Lưu khóa học'}</div>
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className='product-tabs-header'>
                            <ul className='ladiui nav nav-tabs'>
                                <li className={`ladiui nav-item`} onClick={() => this.setSelectedTabIndex(1)}>
                                    <span className={`ladiui tab-link ${selectedTabIndex === 1 ? 'active' : ''}`}>
                                        {t('COURSES.COURSE_INFO')}
                                    </span>
                                </li>
                                <li className={`ladiui nav-item`} onClick={() => this.setSelectedTabIndex(2)}>
                                    <span className={`ladiui tab-link ${selectedTabIndex === 2 ? 'active' : ''}`}>
                                        {t('COURSES.COURSE_CONTENT')}
                                    </span>
                                </li>
                                <li className={`ladiui nav-item`} onClick={() => this.setSelectedTabIndex(3)}>
                                    <span className={`ladiui tab-link ${selectedTabIndex === 3 ? 'active' : ''}`}>
                                        {t('COURSES.COURSE_STUDENT')}
                                    </span>
                                </li>
                                <li className={`ladiui nav-item`} onClick={() => this.setSelectedTabIndex(4)}>
                                    <span className={`ladiui tab-link ${selectedTabIndex === 4 ? 'active' : ''}`}>
                                        {t('COURSES.COURSE_SEO')}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='ladiui page-content'>
                    <div className={`ladiui ${selectedTabIndex == 1 ? '' : 'tab-pane'} fade`} id='tabProductCourseEdit'>
                        <div className='ladiui-product-course-left block-product-content-left'>
                            <div className='block-course-left-item mb-24'>
                                <h3 className='ladiui-label'>Ảnh Thumbnail của khóa học</h3>
                                <div className='thumbnail mr-24'>
                                    <img
                                        className='image'
                                        src={
                                            this.state.images && this.state.images.length > 0
                                                ? `${CDN}${this.state.images[0].src}`
                                                : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'
                                        }
                                        style={{ objectFit: this.state.images && this.state.images.length > 0 ? 'cover' : 'contain' }}
                                        alt=''
                                    />
                                    <div className='ladiui-button-upload block-upload-image'>
                                        <div className='block-overlay'>
                                            <div className='text'>
                                                <i className='ldicon-upload mb-8' />
                                                <p>Tải ảnh Thumbnail</p>
                                                <p>Kích thước (304x171px)</p>
                                            </div>

                                            <input
                                                type='file'
                                                name='file'
                                                accept='image/*'
                                                onChange={this.fileChangedHandler}
                                                onClick={(event) => {
                                                    event.target.value = null;
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='block-course-left-item'>
                                <h3 className='ladiui title-text-form-lever2 mb-0 pb-4'>Đặc điểm nổi bật</h3>
                                <label className='text-default'>
                                    Liệt kê các đặc điểm nổi bật của khóa học (VD: Miễn phí trọn đời, 24h giảng...)
                                </label>

                                <div className='block-list-course-benefits'>
                                    <DragDropContext onDragEnd={this.onDragEndOutstandingFeatures}>
                                        <Droppable droppableId='droppable'>
                                            {(provided, snapshot) => (
                                                <div className='list-requirements' ref={provided.innerRef}>
                                                    {this.listOutstandingFeatures()}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>

                                <div
                                    className='ladiui border-none btn-add-option mb-12 mt-24'
                                    onClick={() => this.addOutstandingFeatures()}
                                >
                                    <img className='mr-8' src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg'} alt='' />
                                    <div className='btn-add-product'> {t('PRODUCTS.ADD_OPTION')}</div>
                                </div>
                            </div>
                            <div className='block-course-left-item'>
                                <h3 className='ladiui title-text-form-lever2 mb-0 pb-4'>Lợi ích khóa học</h3>
                                <label className='text-default'>Liệt kê những lợi ích mà học viên sẽ nhận được từ khóa học của bạn</label>

                                <div className='block-list-course-benefits'>
                                    <DragDropContext onDragEnd={this.onDragEndCourseBenefit}>
                                        <Droppable droppableId='droppable'>
                                            {(provided, snapshot) => (
                                                <div className='list-benefits' ref={provided.innerRef}>
                                                    {this.listItemCourseBenefits()}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>

                                <div className='ladiui border-none btn-add-option mb-12 mt-24' onClick={() => this.addCourseBenefit()}>
                                    <img className='mr-8' src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg'} alt='' />
                                    <div className='btn-add-product'> {t('PRODUCTS.ADD_OPTION')}</div>
                                </div>
                            </div>
                        </div>
                        <div className='ladiui-product-course-right mt-0'>
                            <div className='ladiui form-group mt-0'>
                                <label className='ladiui title-text-form-lever2'>Tên khóa học</label>
                                <Input
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    name='name'
                                    validationName={t('PRODUCTS.NAME')}
                                    placeholder='Tên khóa học'
                                    value={product.name}
                                    onChange={this.onChangeProductInput}
                                    validations={{ isRequired: true }}
                                />
                            </div>
                            <div className='ladiui form-group'>
                                <label className='ladiui title-text-form-lever2'>Mã khóa học</label>
                                <Input
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    name='sku'
                                    validationName={'mã khóa học'}
                                    placeholder='Mã khóa học'
                                    value={product.sku}
                                    onChange={this.onChangeProductInput}
                                    validations={{ isRequired: false }}
                                />
                            </div>
                            <div className='flex mt-24'>
                                <div className='ladiui item-form switch'>
                                    <label className='ladiui switch m-0'>
                                        <input type='checkbox' checked={product.is_one_time} />
                                        <span className='ladiui slider round'></span>
                                    </label>
                                </div>
                                <div className='ml-8'>
                                    <span className='ladiui title-text-form-lever2 mb-0'>{t('PRODUCTS.BUY_ONCE_COURSE')}</span>
                                </div>
                            </div>

                            <div className='ladiui form-group'>
                                <label className='ladiui title-text-form-lever2'>Mô tả ngắn</label>
                                <Input
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    name='short_description'
                                    validationName={'mô tả ngắn'}
                                    placeholder='Nhập mô tả ngắn cho khóa học'
                                    value={product.short_description}
                                    onChange={this.onChangeProductInput}
                                />
                            </div>

                            <div className='ladiui form-group'>
                                <label className='ladiui title-text-form-lever2'>Giảng viên</label>
                                <Input
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    name='hosted_by'
                                    validationName={'tên giảng viên'}
                                    placeholder='Tên giảng viên'
                                    value={product.hosted_by}
                                    onChange={this.onChangeProductInput}
                                />
                            </div>
                            <div className='flex mt-24' style={{ gridColumnGap: '24px' }}>
                                <div className='ladiui form-group m-0'>
                                    <label className='ladiui title-text-form-lever2'>Số lượng học viên</label>
                                    <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        name='total_student'
                                        type='number'
                                        validationName={'số lượng học viên'}
                                        placeholder='Số lượng học viên'
                                        value={product.total_student}
                                        onChange={this.onChangeProductInput}
                                    />
                                </div>
                                <div className='ladiui form-group m-0'>
                                    <label className='ladiui title-text-form-lever2'>Giá khóa học</label>
                                    <NumberInput
                                        name='price'
                                        suffix={currencySymbol}
                                        value={product.price}
                                        onChange={this.onChangeProductInput}
                                    />
                                </div>
                            </div>
                            {this.props.store.userInfo.email && appConfig.WHITE_LIST_EMAIL.includes(this.props.store.userInfo.email) && (
                                <div className='ladiui form-group'>
                                    <label className='ladiui title-text-form-lever2' htmlFor='exampleInputPassword1'>
                                        Cửa hàng
                                    </label>
                                    <SelectManyDropdownStore
                                        data={stores}
                                        _key='name'
                                        _value='value'
                                        pickTitle={'Tiêu đề'}
                                        placeHolder={'Chọn cửa hàng'}
                                        onSubmit={(items) => {
                                            this.setState({
                                                selected_store_ids: items,
                                            });
                                        }}
                                        innerClassName='w100'
                                        styleCustomPeople={{ left: 0, maxHeight: '450px', overflow: 'auto' }}
                                        buttonStyle={{
                                            width: '100%',
                                            paddingRight: '30px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: ' -webkit-box',
                                            WebkitLineClamp: 1,
                                            WebkitBoxOrient: 'vertical',
                                            textAlign: 'left',
                                        }}
                                        selectedItems={selected_store_ids}
                                        isShowBlockInputSearch={true}
                                        isCreateCategory={true}
                                        isCourse={true}
                                    // handleRefreshData={handleRefreshDataStores}
                                    // handleSearchData={handleSearchData}
                                    />
                                </div>
                            )}
                            <div className='ladiui form-group'>
                                <label className='ladiui title-text-form-lever2'>Danh mục</label>
                                <SelectManyDropdown
                                    data={categories}
                                    _key='name'
                                    _value='value'
                                    pickTitle={'Tiêu đề'}
                                    placeHolder={'Chọn danh mục'}
                                    onSubmit={(items) => {
                                        this.setState({
                                            product_category_ids: items,
                                        });
                                    }}
                                    innerClassName='w100'
                                    styleCustomPeople={{ left: 0, maxHeight: '450px', overflow: 'auto' }}
                                    buttonStyle={{
                                        width: '100%',
                                        paddingRight: '30px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: ' -webkit-box',
                                        WebkitLineClamp: 1,
                                        WebkitBoxOrient: 'vertical',
                                        textAlign: 'left',
                                    }}
                                    selectedItems={product_category_ids}
                                    isShowBlockInputSearch={true}
                                    isCreateCategory={true}
                                    isCourse={true}
                                    // handleSearchData={this.handleSearchData}
                                    handleRefreshData={this.handleRefreshDataCategories}
                                />
                            </div>
                            <div className='ladiui form-group'>
                                <label className='ladiui title-text-form-lever2'>{t('PRODUCTS.DESCRIPTION')}</label>
                                <Editor
                                    tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                                    licenseKey="gpl"
                                    value={product.description}
                                    init={{
                                        height: 250,
                                        paste_as_text: true,
                                        extended_valid_elements: 'a[data|href|class|style]',

                                        plugins:
                                            'preview fullpage visualblocks powerpaste checklist fullscreen table lists link image code paste autoresize email',
                                        toolbar:
                                            'bold italic underline | lineheight | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image | numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat | fullscreen  preview email code | insertfile',
                                        toolbar_drawer: 'sliding',
                                        // language_url: '../../../language/tinymce-vi.js',
                                        content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }',
                                        // valid_elements: '*[*]',
                                    }}
                                    onEditorChange={this.handleEditorChange}
                                />
                            </div>

                            <Tag ref={this.tagRef} id='product-tag' allTags={allTags} selectedTags={tags} />
                            <div className='flex mt-24 items-center'>
                                <div className='mr-8'>
                                    <span className='ladiui title-text-form-lever2 mb-0'> {t('PRODUCTS.LB_STATUS')}</span>
                                </div>

                                <div className='ladiui item-form switch'>
                                    <label className='ladiui switch m-0'>
                                        <input
                                            type='checkbox'
                                            onChange={(event) => {
                                                this.setState({
                                                    product: {
                                                        ...this.state.product,
                                                        status:
                                                            this.state.product.status == appConfig.PRODUCT_STATUS.ACTIVE.CODE
                                                                ? appConfig.PRODUCT_STATUS.INACTIVE.CODE
                                                                : appConfig.PRODUCT_STATUS.ACTIVE.CODE,
                                                    },
                                                });
                                            }}
                                            checked={product.status == appConfig.PRODUCT_STATUS.ACTIVE.CODE}
                                        />
                                        <span className='ladiui slider round'></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`ladiui ${selectedTabIndex == 2 ? '' : 'tab-pane'} fade`}>
                        <PanelProductContentCourse
                            ref={this.panelProductContentCourseRef}
                            productInfo={this.state.product}
                            currentStore={this.props.store.userInfo.currentStore || {}}
                            courseModules={this.state.course_modules || []}
                        />
                    </div>
                    <div className={`ladiui ${selectedTabIndex == 3 ? '' : 'tab-pane'} fade`}>
                        <PanelListStudent productId={product.product_id || null} />
                    </div>
                    <div className={`ladiui ${selectedTabIndex == 4 ? '' : 'tab-pane'} fade`}>
                        <PanelProductSellSocial ref={this.productSeoSocialRef} productInfoSeo={product.seo || {}} />
                    </div>
                </div>
            </div>
            // </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadProductImages: (form) => dispatch(fileActions.uploadProductImages(form)),
        listAllTags: () => dispatch(productTagActions.listAll()),
        reloadProduct: (productID) => dispatch(productActions.reload(productID)),
        deleteTag: (productID, productTagID) => dispatch(productTagActions.delete(productID, productTagID)),
        deleteImage: (productID, srcHash) => dispatch(productImageActions.delete(productID, srcHash)),
        createImages: (productImages) => dispatch(productImageActions.create(productImages)),
        show: (productID) => dispatch(productActions.show(productID)),
        update: (product) => dispatch(productActions.update(product)),
        create: (product) => dispatch(productActions.create(product)),
        moduleList: (data) => dispatch(courseActions.moduleList(data)),
        listCategorySelect: (data) => dispatch(productCategoryActions.listSelectCategory(data)),
        listStores: (data) => dispatch(storePageActions.storePageList(data)),
    };
};

const mapStateToProps = (state) => ({
    productImage: { ...state.productImage },
    productTag: { ...state.productTag },
    store: { ...state.store },
    fileReducer: { ...state.file },
    product: { ...state.product },
    course: { ...state.course },
    productCategory: { ...state.productCategory },
    storePage: { ...state.storePage },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation', { withRef: true })(ModalEditCourse)));
