import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const shippingConnect = (data) => ({
    type: types.SETTING_SHIPPING_CONNECT,
    meta: {
        endpoint: endpoint.SETTING_SHIPPING_CONNECT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const shippingDisconnect = (partnerCode, shippingAPIStoreConfigID) => ({
    type: types.SETTING_SHIPPING_DISCONNECT,
    meta: {
        endpoint: endpoint.SETTING_SHIPPING_DISCONNECT,
        method: REQUEST_METHOD.POST,
        body: {
            shipping_partner_code: partnerCode,
            shipping_api_store_config_id: shippingAPIStoreConfigID,
        },
        hasAuth: true,
    },
});

const shippingInactive = (partnerCode, shippingAPIStoreConfigID) => ({
    type: types.SETTING_SHIPPING_INACTIVE,
    meta: {
        endpoint: endpoint.SETTING_SHIPPING_INACTIVE,
        method: REQUEST_METHOD.POST,
        body: {
            shipping_partner_code: partnerCode,
            shipping_api_store_config_id: shippingAPIStoreConfigID,
        },
        hasAuth: true,
    },
});

const shippingActive = (partnerCode, shippingAPIStoreConfigID) => ({
    type: types.SETTING_SHIPPING_ACTIVE,
    meta: {
        endpoint: endpoint.SETTING_SHIPPING_ACTIVE,
        method: REQUEST_METHOD.POST,
        body: {
            shipping_partner_code: partnerCode,
            shipping_api_store_config_id: shippingAPIStoreConfigID,
        },
        hasAuth: true,
    },
});

const listTemplatePrint = () => ({
    type: types.SETTING_LIST_TEMPLATE_PRINT,
    meta: {
        endpoint: endpoint.SETTING_LIST_TEMPLATE,
        method: REQUEST_METHOD.POST,
        body: {
            type: 'print',
        },
        hasAuth: true,
    },
});

const listTemplateEmail = () => ({
    type: types.SETTING_LIST_TEMPLATE_EMAIL,
    meta: {
        endpoint: endpoint.SETTING_LIST_TEMPLATE,
        method: REQUEST_METHOD.POST,
        body: {
            type: 'email',
        },
        hasAuth: true,
    },
});

const updateTemplatePrint = (data) => ({
    type: types.SETTING_UPDATE_TEMPLATE_PRINT,
    meta: {
        endpoint: endpoint.SETTING_UPDATE_TEMPLATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const restoreTemplatePrint = (storeTemplateID) => ({
    type: types.SETTING_RESTORE_TEMPLATE_PRINT,
    meta: {
        endpoint: endpoint.SETTING_RESTORE_TEMPLATE,
        method: REQUEST_METHOD.POST,
        body: {
            store_template_id: storeTemplateID,
        },
        hasAuth: true,
    },
});

const createTemplate = (data) => ({
    type: types.SETTING_CREATE_TEMPLATE,
    meta: {
        endpoint: endpoint.SETTING_CREATE_TEMPLATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const updateTemplateEmail = (data) => ({
    type: types.SETTING_UPDATE_TEMPLATE_EMAIL,
    meta: {
        endpoint: endpoint.SETTING_UPDATE_TEMPLATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const restoreTemplateEmail = (storeTemplateID) => ({
    type: types.SETTING_RESTORE_TEMPLATE_EMAIL,
    meta: {
        endpoint: endpoint.SETTING_RESTORE_TEMPLATE,
        method: REQUEST_METHOD.POST,
        body: {
            store_template_id: storeTemplateID,
        },
        hasAuth: true,
    },
});

const paymentConnect = (data) => ({
    type: types.SETTING_PAYMENT_CONNECT,
    meta: {
        endpoint: endpoint.SETTING_PAYMENT_CONNECT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const paymentDisconnect = (partnerCode, paymentGatewayStoreConfigID) => ({
    type: types.SETTING_PAYMENT_DISCONNECT,
    meta: {
        endpoint: endpoint.SETTING_PAYMENT_DISCONNECT,
        method: REQUEST_METHOD.POST,
        body: {
            payment_gateway_code: partnerCode,
            payment_gateway_store_config_id: paymentGatewayStoreConfigID,
        },
        hasAuth: true,
    },
});

const paymentInactive = (partnerCode, paymentGatewayStoreConfigID) => ({
    type: types.SETTING_PAYMENT_INACTIVE,
    meta: {
        endpoint: endpoint.SETTING_PAYMENT_INACTIVE,
        method: REQUEST_METHOD.POST,
        body: {
            payment_gateway_code: partnerCode,
            payment_gateway_store_config_id: paymentGatewayStoreConfigID,
        },
        hasAuth: true,
    },
});

const paymentActive = (partnerCode, paymentGatewayStoreConfigID) => ({
    type: types.SETTING_PAYMENT_ACTIVE,
    meta: {
        endpoint: endpoint.SETTING_PAYMENT_ACTIVE,
        method: REQUEST_METHOD.POST,
        body: {
            payment_gateway_code: partnerCode,
            payment_gateway_store_config_id: paymentGatewayStoreConfigID,
        },
        hasAuth: true,
    },
});

const updatePaymentGateway = (data) => ({
    type: types.SETTING_PAYMENT_GATEWAY,
    meta: {
        endpoint: endpoint.SETTING_PAYMENT_GATEWAY,
        method: REQUEST_METHOD.POST,
        body: {
            ...data,
        },
        hasAuth: true,
    },
});

const listWebHook = () => ({
    type: types.SETTING_LIST_WEB_HOOK,
    meta: {
        endpoint: endpoint.SETTING_LIST_WEB_HOOK,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    },
});

const createWebHook = (webHook) => ({
    type: types.SETTING_WEB_HOOK_CREATE,
    meta: {
        endpoint: endpoint.SETTING_WEB_HOOK_CREATE,
        method: REQUEST_METHOD.POST,
        body: {
            web_hook: webHook,
        },
        hasAuth: true,
    },
});

const updateWebHook = (webHook) => ({
    type: types.SETTING_WEB_HOOK_UPDATE,
    meta: {
        endpoint: endpoint.SETTING_WEB_HOOK_UPDATE,
        method: REQUEST_METHOD.POST,
        body: {
            web_hook: webHook,
        },
        hasAuth: true,
    },
});

const deleteWebHook = (webHookID) => ({
    type: types.SETTING_WEB_HOOK_DELETE,
    meta: {
        endpoint: endpoint.SETTING_WEB_HOOK_DELETE,
        method: REQUEST_METHOD.POST,
        body: {
            web_hook_id: webHookID,
        },
        hasAuth: true,
    },
});

const listWebHookTransaction = (data) => ({
    type: types.SETTING_WEB_HOOK_TRANSACTION_LIST,
    meta: {
        endpoint: endpoint.SETTING_WEB_HOOK_LIST_TRANSACTION,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const listNotificationEmail = () => ({
    type: types.SETTING_LIST_NOTIFICATION_EMAIL,
    meta: {
        endpoint: endpoint.SETTING_LIST_NOTIFICATION_EMAIL,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    },
});

const listCustomField = () => ({
    type: types.SETTING_LIST_CUSTOM_FIELD,
    meta: {
        endpoint: endpoint.SETTING_LIST_NOTIFICATION_EMAIL,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    },
});

const addCustomField = (data) => ({
    type: types.SETTING_ADD_CUSTOM_FIELD,
    meta: {
        endpoint: endpoint.SETTING_ADD_CUSTOM_FIELD,
        method: REQUEST_METHOD.POST,
        body: {
            custom_field: data,
        },
        hasAuth: true,
    },
});

const listByCustomField = (data) => ({
    type: types.SETTING_LIST_BY_CUSTOM_FIELD,
    meta: {
        endpoint: endpoint.SETTING_LIST_BY_CUSTOM_FIELD,
        method: REQUEST_METHOD.POST,
        body: {
            ...data,
        },
        hasAuth: true,
    },
});

const deleteCustomField = (data) => ({
    type: types.SETTING_DELETE_CUSTOM_FIELD,
    meta: {
        endpoint: endpoint.SETTING_DELETE_CUSTOM_FIELD,
        method: REQUEST_METHOD.POST,
        body: {
            custom_field: data,
        },
        hasAuth: true,
    },
});

const updateCustomField = (data) => ({
    type: types.SETTING_UPDATE_CUSTOM_FIELD,
    meta: {
        endpoint: endpoint.SETTING_UPDATE_CUSTOM_FIELD,
        method: REQUEST_METHOD.POST,
        body: {
            custom_field: data,
        },
        hasAuth: true,
    },
});

const createNotificationEmail = (email) => ({
    type: types.SETTING_CREATE_NOTIFICATION_EMAIL,
    meta: {
        endpoint: endpoint.SETTING_CREATE_NOTIFICATION_EMAIL,
        method: REQUEST_METHOD.POST,
        body: {
            email,
        },
        hasAuth: true,
    },
});

const changeStatusNotificationEmail = (email, status) => ({
    type: types.SETTING_CHANGE_STATUS_NOTIFICATION_EMAIL,
    meta: {
        endpoint: endpoint.SETTING_CHANGE_STATUS_NOTIFICATION_EMAIL,
        method: REQUEST_METHOD.POST,
        body: {
            email,
            status,
        },
        hasAuth: true,
    },
});

const sendNotificationEmail = (email) => ({
    type: types.SETTING_SEND_NOTIFICATION_EMAIL,
    meta: {
        endpoint: endpoint.SETTING_SEND_NOTIFICATION_EMAIL,
        method: REQUEST_METHOD.POST,
        body: {
            email,
        },
        hasAuth: true,
    },
});

const updateNotificationEmailConfigs = (notificationEmailConfigs) => ({
    type: types.SETTING_UPDATE_NOTIFICATION_EMAIL_CONFIG,
    meta: {
        endpoint: endpoint.SETTING_UPDATE_NOTIFICATION_EMAIL_CONFIG,
        method: REQUEST_METHOD.POST,
        body: notificationEmailConfigs,
        hasAuth: true,
    },
});

const deleteNotificationEmail = (email) => ({
    type: types.SETTING_DELETE_NOTIFICATION_EMAIL,
    meta: {
        endpoint: endpoint.SETTING_DELETE_NOTIFICATION_EMAIL,
        method: REQUEST_METHOD.POST,
        body: {
            email,
        },
        hasAuth: true,
    },
});

const listBanksVietQr = () => ({
    type: types.LIST_BANKS_VIET_QR,
    meta: {
        endpoint: endpoint.LIST_BANKS_VIET_QR,
        method: REQUEST_METHOD.GET,
        body: {},
        hasAuth: true,
    },
});

export default {
    shippingConnect,
    shippingDisconnect,
    shippingInactive,
    shippingActive,
    listTemplatePrint,
    updateTemplatePrint,
    restoreTemplatePrint,

    createTemplate,
    listTemplateEmail,
    updateTemplateEmail,
    restoreTemplateEmail,

    paymentConnect,
    paymentDisconnect,
    paymentActive,
    paymentInactive,
    updatePaymentGateway,
    listWebHook,
    createWebHook,
    updateWebHook,
    deleteWebHook,
    listWebHookTransaction,

    listNotificationEmail,
    createNotificationEmail,
    sendNotificationEmail,
    deleteNotificationEmail,
    changeStatusNotificationEmail,
    updateNotificationEmailConfigs,

    listCustomField,
    addCustomField,
    listByCustomField,
    deleteCustomField,
    updateCustomField,

    listBanksVietQr,
};
