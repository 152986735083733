import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import { I18nContext, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import produce from 'immer';
import Input from '../../../components/Input';
import { map, find } from 'lodash';
import ModalAddCategory from './ModalAddCategory';

import fileActions from '../../../redux/futures/file/actions';
import * as fileTypes from '../../../redux/futures/file/types';

import productCategoryActions from '../../../redux/futures/product_category/actions';
import * as productCategoryTypes from '../../../redux/futures/product_category/types';

import baseHelper from '../../../helpers/BaseHelper';

import appConfig from '../../../config/app';

import { SketchPicker } from 'react-color';

import Popover from '../../../components/Popover';

import { SimpleTreeItemWrapper, FolderTreeItemWrapper, SortableTree, TreeItemComponentProps, TreeItems } from 'dnd-kit-sortable-tree';

// import 'react-sortable-tree/style.css';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const CDN = 'https://w.ladicdn.com/';

const PanelSettingMenuBanner = forwardRef((props, ref) => {
    let { isShow, currentDataMenu, handlePreviewPageCourse } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const fileReducer = useSelector((state) => state.file);

    const productCategoryReducer = useSelector((state) => state.productCategory);

    const courseReducer = useSelector((state) => state.course);

    const [categories, setCategories] = useState([]);
    const [dataConfig, setDataConfig] = useState({
        background: '#002333',
        text_color: '#FFFFFF',
    });

    const [heroSection, setHeroSection] = useState({
        background: '#002333',
        text_color: '#FFFFFF',
    });

    const [isShowInputAddLink, setIsShowInputAddLink] = useState(false);
    const [indexBanner, setIndexBanner] = useState('');
    const [isShowBlockAddCategory, setIsShowBlockAddCategory] = useState(false);

    const [valueSeachCategory, setValueSearchCategory] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedCategoriesLength, setSelectedCategoriesLength] = useState(0);

    const inputsRef = new Set();
    const wrapperRef = useRef();

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return {
                background: dataConfig.background,
                text_color: dataConfig.text_color,
                hero_section: {
                    background: heroSection.background,
                    text_color: heroSection.text_color,
                },
                menu_category_ids: selectedCategories && selectedCategories.length > 0 ? selectedCategories : [],
                // menu_category_ids: selectedCategories && selectedCategories.length > 0 ? getDataMenuCategoryIds() : [],
            };
        },
    }));

    useEffect(() => {
        if (productCategoryReducer && productCategoryReducer.action == productCategoryTypes.LIST_SELECT_CATEGORY) {
            if (productCategoryReducer.status) {
                if (productCategoryReducer.listSelects && productCategoryReducer.listSelects.length > 0) {
                    setCategories(productCategoryReducer.listSelects);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productCategoryReducer.message, 'OK');
            }
        }
    }, [productCategoryReducer]);

    useEffect(() => {
        if (currentDataMenu) {
            setDataConfig({
                background: currentDataMenu.background || '#002333',
                text_color: currentDataMenu.text_color || '#FFFFFF',
            });
            if (currentDataMenu.menu_categories && currentDataMenu.menu_categories.length > 0) {
                let data = [];
                currentDataMenu.menu_categories.map((item) => {
                    data.push({
                        ...item,
                        title: item.name ? item.name : '',
                        children: item.children && item.children.length > 0 ? item.children : [],
                    });
                });
                // setSelectedCategories(currentDataMenu.menu_categories);
                setSelectedCategories(data);
            }

            if (currentDataMenu && currentDataMenu.hero_section) {
                setHeroSection({
                    background: currentDataMenu.hero_section.background || '#002333',
                    text_color: currentDataMenu.hero_section.text_color || '#FFFFFF',
                });
            }
        }
    }, [currentDataMenu]);

    const countLengthMenus = async (menuIds) => {
        let length = 0;
        for await (let menu of menuIds) {
            length = length + 1;
            if (menu.children) {
                let length2 = await countLengthMenus(menu.children);
                length = length + length2;
            }
        }

        return length;
    };

    useEffect(() => {
        const getMenuSelectedLength = async () => {
            let _selectedCategoriesLength = await countLengthMenus(selectedCategories);
            setSelectedCategoriesLength(_selectedCategoriesLength);
        };
        if (selectedCategories && selectedCategories.length > 0) {
            getMenuSelectedLength();
        }
    }, [selectedCategories]);

    const onChangeInput = (event) => {
        let { value } = event.target;
        setDataConfig(
            produce(dataConfig, (draft) => {
                draft[event.target.name] = value;
            })
        );
    };

    const setColorPicker = (color) => {
        setDataConfig({
            ...dataConfig,
            background: color,
        });
    };

    const renderColorPickerItem = () => {
        return appConfig.LIST_COLOR_PICKER.map((value, index) => {
            return (
                <div
                    className={`color-item ${dataConfig.background == value ? 'checked' : ''}`}
                    key={index}
                    style={{ backgroundColor: value }}
                    onClick={() => setColorPicker(value)}
                >
                    {dataConfig.background == value && <i className='ladiui icon icon-checked'></i>}
                </div>
            );
        });
    };

    const renderHeroSectionColorPickerItem = () => {
        return appConfig.LIST_COLOR_PICKER.map((value, index) => {
            return (
                <div
                    className={`color-item ${heroSection.background == value ? 'checked' : ''}`}
                    key={index}
                    style={{ backgroundColor: value }}
                    onClick={() => setColorPickerHeroSection(value)}
                >
                    {heroSection.background == value && <i className='ladiui icon icon-checked'></i>}
                </div>
            );
        });
    };

    const setColorPickerHeroSection = (color) => {
        setHeroSection({
            ...heroSection,
            background: color,
        });
    };

    const handleSubmitData = () => {
        props.handleSubmitData();
    };

    const onKeyDownInput = (event, _index) => {
        if (event.key === 'Enter') {
            let selectedBanners = dataConfig.banners || [];
            selectedBanners.map((item, index) => {
                if (index == _index) {
                    item.link = event.target.value;
                }
            });

            setDataConfig({
                ...dataConfig,
                banners: selectedBanners,
            });

            setIsShowInputAddLink(false);
        }
    };

    const handleShowBlockAddCategory = (status) => {
        if (status) {
            let payload = {
                search: {
                    type: 'Course',
                },
            };
            dispatch(productCategoryActions.listSelectCategory(payload));
        }
        setValueSearchCategory('');
        setIsShowBlockAddCategory(status);
    };

    const handleAddCategories = (data) => {
        // let contents = [];
        let contents = [...selectedCategories];
        if (data && data.length > 0) {
            data.map((item) => {
                contents.push({
                    ...item,
                    title: item.name ? item.name : '',
                    children: [],
                });
            });
        }
        setSelectedCategories(contents);

        setIsShowBlockAddCategory(false);
    };

    // const handleDeleteCategoryItem = (item) => {
    //   let _selectedDeleteCategories = selectedCategories.filter((_item) => _item.product_category_id != item.product_category_id);

    //   if (_selectedDeleteCategories) {
    //     setSelectedCategories(_selectedDeleteCategories);
    //   }
    // };

    // Kéo thả giao diện

    //hàm giúp thực hiện việc sắp xếp khi kéo thả
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    // const onDragEndCategory = (result) => {
    //   if (!result.destination) {
    //     return;
    //   }

    //   const _selectedCategories = reorder(selectedCategories, result.source.index, result.destination.index);

    //   setSelectedCategories(_selectedCategories);
    // };

    // const listCategoryItem = () => {
    //   return selectedCategories.map((item, index) => {
    //     return (
    //       <Draggable key={index} draggableId={"" + index} index={index}>
    //         {(provided, snapshot) => (
    //           <div
    //             key={index}
    //             className="category-list-item"
    //             ref={provided.innerRef}
    //             {...provided.draggableProps}
    //             {...provided.dragHandleProps}
    //             style={{
    //               ...provided.draggableProps.style,
    //               userSelect: "none",
    //               background: snapshot.isDragging ? "#e8f0fe" : "none",
    //             }}
    //           >
    //             <div className="content-top-item">
    //               <div className="icon-name flex">
    //                 <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg" />
    //                 <span className="ml-16 ladiui-label pb-0 w100">{item.name}</span>
    //               </div>
    //               <div className="action-right">
    //                 <div className="btn-delete cursor-pointer" onClick={() => handleDeleteCategoryItem(item)}>
    //                   <i className="ladi-icon icon-bin"></i>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         )}
    //       </Draggable>
    //     );
    //   });
    // };

    const handleActionPreviewPageCourse = () => {
        handlePreviewPageCourse();
    };

    const handleShowBackgroundPicker = () => {
        document.getElementById('picker-id-background-color').click();
    };

    const handleChangeBackgroundPicker = (color) => {
        setDataConfig({
            ...dataConfig,
            background: color.hex,
        });
    };

    const handleShowHeroSectionPicker = () => {
        document.getElementById('picker-id-hero-section').click();
    };

    const handleChangeBackgroundHeroSectionPicker = (color) => {
        setHeroSection({
            ...heroSection,
            background: color.hex,
        });
    };

    const handleChangeTreeData = (data) => {
        setSelectedCategories(data);
    };

    const deleteTableTreeItem = async (menus, productCateId) => {
        let _selectedCates = [];
        let isExit = menus.some((value) => value.product_category_id == productCateId);
        if (isExit) {
            _selectedCates = menus.filter((item) => item.product_category_id != productCateId);
            return _selectedCates;
        }

        for await (let menu of menus) {
            if (menu.children) {
                menu.children = await deleteTableTreeItem(menu.children, productCateId);
            }
            _selectedCates.push(menu);
        }

        return _selectedCates;
    };

    const handleDeleteTableTree = async (e, data) => {
        e.stopPropagation();
        if (data.product_category_id) {
            let _selectedCates = [];
            let isExit = selectedCategories.some((value) => value.product_category_id == data.product_category_id);
            if (isExit) {
                _selectedCates = selectedCategories.filter((item) => item.product_category_id != data.product_category_id);
                setSelectedCategories(_selectedCates);
                return;
            }

            for await (let item of selectedCategories) {
                if (item.children) {
                    item.children = await deleteTableTreeItem(item.children, data.product_category_id);
                }
                _selectedCates.push(item);
            }

            setSelectedCategories(_selectedCates);
        }
    };

    const TreeItem = React.forwardRef((props, ref) => {
        return (
            <SimpleTreeItemWrapper {...props} ref={ref}>
                <div className='tree-item-content-action'>
                    <span>{props.item.name}</span>
                    <span
                        onClick={(event) => handleDeleteTableTree(event, props.item)}
                        style={{
                            position: 'absolute',
                            right: 10,
                            cursor: 'pointer',
                        }}
                    >
                        <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-delete.svg' />
                    </span>
                </div>
            </SimpleTreeItemWrapper>
        );
    });

    return (
        <div className='page-setting' style={isShow ? { display: 'block' } : { display: 'none' }}>
            <div className='block-title-action'>
                <div className='title'>
                    <h3>Thiết lập menu và màu sắc</h3>
                </div>
                <div className='actions flex'>
                    <button className='ladiui btn btn-outline-primary mr-24' onClick={() => handleActionPreviewPageCourse()}>
                        Xem trước
                    </button>
                    <button className='ladiui btn btn-primary' onClick={() => handleSubmitData()}>
                        Lưu trang khóa học
                    </button>
                </div>
            </div>
            <div className='setting-content-menu-banner config-panel'>
                <div>
                    <div className='ladiui pb-0 flex-center-start title'>
                        <span> Màu sắc chủ đạo </span>

                        <div
                            className='item-tracking'
                            data-tooltip={'Áp dụng với thanh Menu, nút CTA và nút thêm vào giỏ hàng'}
                            data-tooltip-position='bottom'
                        >
                            <span className='flex'>
                                <i className='ladi-icon icon-c-question ml-8' />
                            </span>
                        </div>
                    </div>
                    <div className='flex mt-16'>
                        <div className='ladiui pr-24' style={{ borderRight: '1px solid #e5e5e5' }}>
                            <label className='ladiui-label bold-400'>Màu nền</label>
                            <div className='mt-4'>
                                <div className='color-picker flex relative'>
                                    {renderColorPickerItem()}
                                    {!appConfig.LIST_COLOR_PICKER.includes(dataConfig.background) && (
                                        <div className={`color-item checked`} style={{ backgroundColor: dataConfig.background }}>
                                            <i
                                                className='ladiui icon icon-checked'
                                                style={
                                                    dataConfig.background == '#FFFFFF' || dataConfig.background == '#ffffff'
                                                        ? { backgroundColor: '#6d6d6d' }
                                                        : {}
                                                }
                                            ></i>
                                        </div>
                                    )}
                                    <div className='color-item btn-add-background' onClick={() => handleShowBackgroundPicker()}>
                                        +
                                    </div>
                                    <Popover
                                        wrapperPopoverProps={{ className: 'popover-picker' }}
                                        styleCustom={{ bottom: '-150', right: '-220', position: 'absolute', zIndex: '99999' }}
                                        content={
                                            <SketchPicker
                                                color={dataConfig.background}
                                                onChangeComplete={(color) => handleChangeBackgroundPicker(color)}
                                            />
                                        }
                                    >
                                        <div id={`picker-id-background-color`}> </div>
                                    </Popover>
                                </div>
                            </div>
                        </div>
                        <div className='ladiui ml-24'>
                            <label className='ladiui-label bold-400'>Màu chữ</label>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <span
                                        className='color-item select-text-color white'
                                        onClick={() =>
                                            setDataConfig({
                                                ...dataConfig,
                                                text_color: '#FFFFFF',
                                            })
                                        }
                                    >
                                        {dataConfig.text_color == '#FFFFFF' && <i className='ladiui icon icon-checked'></i>}
                                    </span>
                                    <span
                                        className='color-item select-text-color black'
                                        onClick={() =>
                                            setDataConfig({
                                                ...dataConfig,
                                                text_color: '#000000',
                                            })
                                        }
                                    >
                                        {dataConfig.text_color == '#000000' && <i className='ladiui icon icon-checked'></i>}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-24'>
                    <h3 className='title'>Màu nền trang giới thiệu khóa học</h3>
                    <div className='flex mt-16'>
                        <div className='ladiui pr-24' style={{ borderRight: '1px solid #e5e5e5' }}>
                            <label className='ladiui-label bold-400'>Màu nền</label>
                            <div className='mt-4'>
                                <div className='color-picker flex relative'>
                                    {renderHeroSectionColorPickerItem()}
                                    {!appConfig.LIST_COLOR_PICKER.includes(heroSection.background) && (
                                        <div className={`color-item checked`} style={{ backgroundColor: heroSection.background }}>
                                            <i
                                                className='ladiui icon icon-checked'
                                                style={
                                                    heroSection.background == '#FFFFFF' || heroSection.background == '#ffffff'
                                                        ? { backgroundColor: '#6d6d6d' }
                                                        : {}
                                                }
                                            ></i>
                                        </div>
                                    )}
                                    <div className='color-item btn-add-background' onClick={() => handleShowHeroSectionPicker()}>
                                        +
                                    </div>
                                    <Popover
                                        wrapperPopoverProps={{ className: 'popover-picker' }}
                                        styleCustom={{ bottom: '-150', right: '-220', position: 'absolute', zIndex: '999999' }}
                                        content={
                                            <SketchPicker
                                                color={heroSection.background}
                                                onChangeComplete={(color) => handleChangeBackgroundHeroSectionPicker(color)}
                                            />
                                        }
                                    >
                                        <div id={`picker-id-hero-section`}> </div>
                                    </Popover>
                                </div>
                            </div>
                        </div>
                        <div className='ladiui ml-24'>
                            <label className='ladiui-label bold-400'>Màu chữ</label>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <span
                                        className='color-item select-text-color white'
                                        onClick={() =>
                                            setHeroSection({
                                                ...heroSection,
                                                text_color: '#FFFFFF',
                                            })
                                        }
                                    >
                                        {heroSection.text_color == '#FFFFFF' && <i className='ladiui icon icon-checked'></i>}
                                    </span>
                                    <span
                                        className='color-item select-text-color black'
                                        onClick={() =>
                                            setHeroSection({
                                                ...heroSection,
                                                text_color: '#000000',
                                            })
                                        }
                                    >
                                        {heroSection.text_color == '#000000' && <i className='ladiui icon icon-checked'></i>}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='block-category-top-menu mt-24'>
                    <label className='title'>Danh mục trên menu</label>
                    <span className='sub-title'>Chọn danh mục sẽ hiển thị trên thanh menu khóa học của bạn</span>
                    {selectedCategories && selectedCategories.length > 0 && (
                        // <div className="block-list-category" style={{ maxHeight: "400px", height: (selectedCategoriesLength * 62) + "px" }}>
                        <div className='block-list-category'>
                            {/* {selectedCategories && selectedCategories.length > 0 && (
                <DragDropContext onDragEnd={onDragEndCategory}>
                  <Droppable droppableId="droppable" type="droppableItem">
                    {(provided, snapshot) => (
                      <div
                        className="block-list-category"
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDragging ? "#e8f0fe" : "none",
                        }}
                      >
                        {listCategoryItem()}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )} */}
                            {/* <SortableTree
                treeData={selectedCategories}
                onChange={(treeData) => handleChangeTreeData(treeData)}
                generateNodeProps={extendedNode => ({
                  title: (
                    <div className="flex">
                      <span className="pr-16" style={{ color: "#4d4d4d" }}>{extendedNode.node.title}</span>
                      <span
                        onClick={() => handleDeleteTableTree(extendedNode.node)}
                        style={{
                          position: "absolute",
                          right: 10,
                          cursor: "pointer"
                        }}
                      ><img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-delete.svg" /></span>
                    </div>
                  ),
                  // buttons: [<button>Delete</button>],
                })}
              /> */}
                            <SortableTree
                                items={selectedCategories}
                                onItemsChanged={(treeData) => {
                                    handleChangeTreeData(treeData);
                                }}
                                TreeItemComponent={TreeItem}
                            />
                        </div>
                    )}

                    <div className='ladiui border-none btn-add-option mb-12 mt-24 pb-24'>
                        <img className='mr-8' src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg' />
                        <div className='btn-add-product' onClick={() => handleShowBlockAddCategory(true)}>
                            Thêm danh mục
                        </div>
                    </div>
                </div>
            </div>
            {isShowBlockAddCategory && (
                <ModalAddCategory
                    onClose={() => handleShowBlockAddCategory(false)}
                    isShow={isShowBlockAddCategory}
                    dataSelectCategories={categories || []}
                    selectedCategories={selectedCategories || []}
                    handleAddCategories={handleAddCategories}
                    innerStyle={{ display: 'none' }}
                />
            )}
        </div>
    );
});
PanelSettingMenuBanner.propTypes = {};

export default PanelSettingMenuBanner;
