import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import baseHelper from '../../../../helpers/BaseHelper';
import { useTranslation } from 'react-i18next';
import Input from '../../../../components/Input';
import appConfig from '../../../../config/app';
import { map, includes } from 'lodash';
import Dropdown from '../../../../components/Dropdown';

import { appLocalStorage } from '../../../../localforage';

import apiActions from '../../../../redux/futures/api/actions';
import * as apiTypes from '../../../../redux/futures/api/types';

import storeActions from '../../../../redux/futures/store/actions';
import storeTypes from '../../../../redux/futures/store/types';

import settingActions from '../../../../redux/futures/setting/actions';
import * as settingTypes from '../../../../redux/futures/setting/types';

ModalBank.propTypes = {
    onCancel: PropTypes.func,
    paymentGatewayConfigured: PropTypes.any,
};

export default function ModalBank(props) {
    const {
        paymentGatewayConfigured,
        onCancel,
        upDateDataCofigPayment,
        connect,
        isSubmitLoading,
        onchangeDataUpdateBank,
        bankMode,
        dataUpdateBanks,
        bankRef,
        bankAccount,
        onChangeBankInput,
        selectedDataBank,
        setBankMode,
        onChangeBankDataInput,
        onChangeBankDataUpdateInput,
        onChangeBankWebhook,
        selectedDataUpdateBank,
        logo,
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const settingReducer = useSelector((state) => state.setting);
    const apiReducer = useSelector((state) => state.api);
    const storeReducer = useSelector((state) => state.store);

    const [listBanks, setListBanks] = useState([]);
    const [isEditVietQR, setIsEditVietQR] = useState(true);
    const [listApikey, setListApikey] = useState([]);
    const [urlBankWebhook, setUrlBankWebhook] = useState(appConfig.BANK_WEBHOOK);
    useEffect(() => {
        dispatch(settingActions.listBanksVietQr());

        appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
            const { ladi_uid: ladiUID } = result;
            const storeID = result.currentStore.store_id;

            if (storeID) {
                dispatch(storeActions.show(storeID));
            }
        });
    }, []);

    useEffect(() => {
        if (listApikey.length > 0) {
            setUrlBankWebhook(urlBankWebhook + listApikey[0].api_key);
            onChangeBankWebhook(urlBankWebhook + listApikey[0].api_key)
        }
    }, [listApikey]);

    useEffect(() => {
        if (apiReducer.action == apiTypes.API_LIST_THIRD_PARTY) {
            if (apiReducer.status) {
                setListApikey(apiReducer.third_parties || []);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', apiReducer.message, 'OK');
            }
        }
    }, [apiReducer]);

    useEffect(() => {
        if (settingReducer.action == settingTypes.LIST_BANKS_VIET_QR) {
            if (settingReducer.status) {
                setListBanks(settingReducer.list_banks || []);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', settingReducer.message, 'OK');
            }
        }
    }, [settingReducer]);

    const handleCopyEmailHook = (emailHook) => {
        if (emailHook) {
            navigator.clipboard.writeText(emailHook);
            window.LadiUI.toastCustom('success', '', t('COMMON.COPIED'));
        }
    };

    return (
        <Modal
            id='modal-bank-pay'
            title={!paymentGatewayConfigured ? t('PAYMENTS.SET_UP_PAYMENT_METHOD') : t('PAYMENTS.DETAIL_PAYMENT_METHOD')}
            onCancel={onCancel}
            hasFooter={true}
            // onOk={
            //   !paymentGatewayConfigured
            //     ? () => connect(appConfig.PAYMENT_PARTNERS.BANK.CODE)
            //     : () => upDateDataCofigPayment(appConfig.PAYMENT_PARTNERS.BANK.CODE, paymentGatewayConfigured)
            // }
            visible={true}
            width={1000}
            logo={logo}
            bodyStyles={{ minHeight: '40vh' }}
            isCustomFooter={true}
            footer={
                <div className='ladiui-modal-footer'>
                    <div id='data-tooltip-guide' className='ladiui help-integrate'>
                        <span className='tooltip-icon flex mr-10' data-tooltip-position='right'>
                            {' '}
                            <i className='ladi-icon icon-c-question' />
                        </span>
                        <span>
                            Hướng dẫn thiết lập phương thức thanh toán qua&nbsp;
                            <a
                                href='https://help.ladisales.vn/cai-dat-tai-khoan/phuong-thuc-thanh-toan/chuyen-khoan-qua-ngan-hang'
                                target='_blank'
                            >
                                Ngân hàng
                            </a>
                        </span>
                    </div>
                    <div className='flex'>
                        <button onClick={onCancel} className='ladiui btn btn-secondary mr-12'>
                            {t('ACTIONS.CLOSE')}
                        </button>
                        <button
                            onClick={
                                !paymentGatewayConfigured
                                    ? () => connect(appConfig.PAYMENT_PARTNERS.BANK.CODE)
                                    : () => upDateDataCofigPayment(appConfig.PAYMENT_PARTNERS.BANK.CODE, paymentGatewayConfigured)
                            }
                            className={`ladiui btn btn-primary ${props.isLoading ? 'loader' : ''}`}
                        >
                            {t('ACTIONS.SAVE')}
                        </button>
                    </div>
                </div>
            }
        >
            <div className='content mt-0'>
                {paymentGatewayConfigured ? (
                    <React.Fragment>
                        <div className='partner-account'>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('PAYMENTS.LB_METHOD_NAME')}</label>
                                <Input
                                    ref={(ref) => bankRef.add(ref)}
                                    value={dataUpdateBanks.method_name}
                                    validationName={t('PAYMENTS.LB_METHOD_NAME')}
                                    name='method_name'
                                    onChange={(e) => onchangeDataUpdateBank(e)}
                                    validations={{ isRequired: true }}
                                />
                            </div>

                            {dataUpdateBanks.banks &&
                                dataUpdateBanks.banks.length > 0 &&
                                dataUpdateBanks.banks.map((bankItem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className='ladiui form-group'>
                                                <label className='ladiui-label'>Chọn ngân hàng</label>
                                                <Dropdown
                                                    id='state'
                                                    data={listBanks}
                                                    onSelectItem={(item) => {
                                                        selectedDataUpdateBank(item, index);
                                                    }}
                                                    currentKey={bankItem.bank.id}
                                                    currentValue={bankItem.bank.name}
                                                    _key={'id'}
                                                    _value={'name'}
                                                    validationName={t('COMMON.STATE_OR_CITY')}
                                                    placeHolder={t('Chọn ngân hàng')}
                                                    isShowBlockInputSearch={true}
                                                    isDropdownBank={true}
                                                />
                                            </div>
                                            {bankItem &&
                                                (appConfig.LIST_BANK_AUTO_PAYMENT.includes(bankItem.bank.shortName) ||
                                                    appConfig.SEPAY_BANK.includes(bankItem.bank.code))
                                                && (
                                                    <>
                                                        <div className='laiui form-group mt-4'>
                                                            <div className='flex'>
                                                                <input
                                                                    type='checkbox'
                                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                                                    name='autoConfirmPayment'
                                                                    onChange={(event) => onChangeBankDataUpdateInput(event, index)}
                                                                    checked={bankItem.autoConfirmPayment}
                                                                />
                                                                <span className=''>Tự động xác nhận thanh toán</span>
                                                            </div>
                                                        </div>
                                                        {
                                                            appConfig.LIST_BANK_AUTO_PAYMENT.includes(bankItem.bank.shortName) && bankItem.autoConfirmPayment &&
                                                            <div className='flex mt-24' style={{ alignItems: 'end' }}>
                                                                <div className='ladiui form-group mb-0'>
                                                                    <div className='flex mb-8 space-between'>
                                                                        <label className='ladiui-label pb-0'>Xác thực qua Email chuyển tiếp</label>
                                                                        <span style={{ fontSize: 12 }}>
                                                                            <a
                                                                                style={{ marginRight: "5px" }}
                                                                                href='https://help.ladisales.vn/cai-dat-tai-khoan/phuong-thuc-thanh-toan/chuyen-khoan-qua-ngan-hang/tu-dong-xac-nhan-da-thanh-toan-cho-don-hang-chuyen-khoan-qua-ngan-hang'
                                                                                target='_blank'
                                                                            >
                                                                                Hướng dẫn
                                                                            </a>
                                                                            cài đặt chuyển tiếp email sau khi thanh toán
                                                                        </span>
                                                                    </div>
                                                                    <input
                                                                        type='text'
                                                                        readOnly
                                                                        className='ladiui form-control'
                                                                        value={storeReducer.store ? storeReducer.store.email_hook : ''}
                                                                    />
                                                                </div>
                                                                <button
                                                                    className='ladiui btn btn-outline-primary ml-24'
                                                                    onClick={() => handleCopyEmailHook(storeReducer.store.email_hook || '')}
                                                                >
                                                                    {t('ACTIONS.COPY')}
                                                                </button>
                                                            </div>
                                                        }
                                                        {
                                                            appConfig.SEPAY_BANK.includes(bankItem.bank.code) && bankItem.autoConfirmPayment &&
                                                            <div className='flex mt-24' style={{ alignItems: 'end' }}>
                                                                <div className='ladiui form-group mb-0'>
                                                                    <div className='flex mb-8 space-between'>
                                                                        <label className='ladiui-label pb-0'>Xác thực qua Webhook</label>
                                                                        <span style={{ fontSize: 12 }}>
                                                                            <a
                                                                                style={{ marginRight: "5px" }}
                                                                                href='http://ldp.ink/hd-cau-hinh-webhook-checkout'
                                                                                target='_blank'
                                                                            >
                                                                                Hướng dẫn
                                                                            </a>
                                                                            cài đặt cấu hình webhook để tự động xác nhận
                                                                        </span>
                                                                    </div>
                                                                    <input
                                                                        type='text'
                                                                        readOnly
                                                                        className='ladiui form-control'
                                                                        value={urlBankWebhook}
                                                                    />
                                                                </div>
                                                                <button
                                                                    className='ladiui btn btn-outline-primary ml-24'
                                                                    onClick={() => handleCopyEmailHook(urlBankWebhook)}
                                                                >
                                                                    {t('ACTIONS.COPY')}
                                                                </button>
                                                            </div>
                                                        }
                                                    </>
                                                )}
                                            <div className='ladiui form-group'>
                                                <label className='ladiui-label'>Số tài khoản</label>
                                                <Input
                                                    value={bankItem.accountNumber}
                                                    name='accountNumber'
                                                    onChange={(event) => onChangeBankDataUpdateInput(event, index)}
                                                    placeholder={'Nhập số tài khoản'}
                                                />
                                            </div>
                                            <div className='ladiui form-group'>
                                                <label className='ladiui-label'>Tên chủ tài khoản</label>
                                                <Input
                                                    value={bankItem.accountName}
                                                    name='accountName'
                                                    placeholder={'Nhập tên chủ tài khoản'}
                                                    onChange={(event) => onChangeBankDataUpdateInput(event, index)}
                                                />
                                            </div>
                                            <div className='ladiui form-group'>
                                                <label className='ladiui-label'>Tiền tố nội dung chuyển khoản (Bắt buộc)</label>
                                                <Input
                                                    ref={(ref) => bankRef.add(ref)}
                                                    validationName={'tiền tố nội dung chuyển khoản'}
                                                    value={bankItem.transferContentPrefix}
                                                    name='transferContentPrefix'
                                                    placeholder={'Nhập tiền tố nội dung chuyển khoản'}
                                                    onChange={(event) => onChangeBankDataUpdateInput(event, index)}
                                                    validations={{ isRequired: true }}
                                                />
                                            </div>
                                            {(!appConfig.LIST_BANK_AUTO_PAYMENT.includes(bankItem.bank.shortName) && !appConfig.SEPAY_BANK.includes(bankItem.bank.code)) && (
                                                <div className='ladiui form-group'>
                                                    <div className='flex space-between pb-8'>
                                                        <label className='ladiui-label pb-0'>Nội dung chuyển khoản (Không bắt buộc)</label>
                                                        {/* <a href="https://my.vietqr.io/vietqr/templates" target="_blank" className="ml-8">Danh sách mã tùy chỉnh</a> */}
                                                    </div>
                                                    <Input
                                                        value={bankItem.transactionContent}
                                                        name='transactionContent'
                                                        placeholder={'Nội dung chuyển khoản'}
                                                        onChange={(event) => onChangeBankDataUpdateInput(event, index)}
                                                    />
                                                </div>
                                            )}
                                            <div className='ladiui form-group'>
                                                <div className='flex pb-8'>
                                                    <label className='ladiui-label pb-0'>Template VietQR ID (Không bắt buộc)</label>
                                                    <a
                                                        href='https://help.ladisales.vn/cai-dat-tai-khoan/phuong-thuc-thanh-toan/chuyen-khoan-qua-ngan-hang/huong-dan-tao-template-vietqr-id'
                                                        target='_blank'
                                                        className='ml-8'
                                                    >
                                                        Hướng dẫn tạo template mẫu
                                                    </a>
                                                </div>
                                                <div className='flex'>
                                                    <Input
                                                        value={bankItem.template_id}
                                                        name='template_id'
                                                        placeholder={'Nhập vào Template VietQR ID của bạn'}
                                                        id='inputVietQr'
                                                        onChange={(event) => onChangeBankDataUpdateInput(event, index)}
                                                        disabled={isEditVietQR}
                                                    />
                                                    <button
                                                        className='ladiui btn btn-outline-primary ml-24'
                                                        onClick={() => {
                                                            setIsEditVietQR(!isEditVietQR);
                                                            document.getElementById('inputVietQr').focus();
                                                        }}
                                                    >
                                                        Chỉnh sửa
                                                    </button>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}

                            <div id='data-tooltip-guide' className='ladiui form-group'>
                                <label className='ladiui-label'>
                                    <span className='tooltip-paymet-guide'>{t('PAYMENTS.LB_PAYMENT_GUIDE')}</span>
                                    <span
                                        className='tooltip-icon'
                                        data-tooltip={t('PAYMENTS.LB_TT_PAYMENT_GUIDE')}
                                        data-tooltip-position='right'
                                    >
                                        {' '}
                                        <i className='ladi-icon icon-c-question' />
                                    </span>
                                </label>
                                <textarea
                                    name='payment_guide'
                                    className='ladiui textarea'
                                    defaultValue={paymentGatewayConfigured.config.payment_guide}
                                    onChange={(e) => onchangeDataUpdateBank(e)}
                                />
                            </div>
                            {baseHelper.isDevelopEnv() && (
                                <div className='ladiui form-group flex'>
                                    <input
                                        id='bank_mode'
                                        type='checkbox'
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                        checked={bankMode === appConfig.MODE.TEST}
                                        disabled={true}
                                    />
                                    <label htmlFor='bank_mode'> {t('MODE.TEST')}</label>
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div>
                            <div className='partner-account'>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('PAYMENTS.LB_METHOD_NAME')}</label>
                                    <Input
                                        ref={(ref) => bankRef.add(ref)}
                                        value={bankAccount.method_name}
                                        validationName={t('PAYMENTS.LB_METHOD_NAME')}
                                        name='method_name'
                                        onChange={(event) => onChangeBankInput(event)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>

                                {bankAccount.banks &&
                                    bankAccount.banks.length > 0 &&
                                    bankAccount.banks.map((bankItem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div className='ladiui form-group'>
                                                    <label className='ladiui-label'>Chọn ngân hàng</label>
                                                    <Dropdown
                                                        id='state'
                                                        data={listBanks}
                                                        onSelectItem={(item) => {
                                                            selectedDataBank(item, index);
                                                        }}
                                                        currentKey={bankItem.bank.id}
                                                        currentValue={bankItem.bank.name}
                                                        _key={'id'}
                                                        _value={'name'}
                                                        validationName={t('COMMON.STATE_OR_CITY')}
                                                        placeHolder={t('Chọn ngân hàng')}
                                                        isShowBlockInputSearch={true}
                                                        isDropdownBank={true}
                                                    />
                                                </div>
                                                {bankItem && appConfig.LIST_BANK_AUTO_PAYMENT.includes(bankItem.bank.shortName) && (
                                                    <>
                                                        <div className='laiui form-group mt-4'>
                                                            <div className='flex'>
                                                                <input
                                                                    type='checkbox'
                                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                                                    name='autoConfirmPayment'
                                                                    onChange={(event) => onChangeBankDataInput(event, index)}
                                                                    checked={bankItem.autoConfirmPayment}
                                                                />
                                                                <span className=''>Tự động xác nhận thanh toán</span>
                                                            </div>
                                                        </div>
                                                        <div className='flex mt-24' style={{ alignItems: 'end' }}>
                                                            <div className='ladiui form-group mb-0'>
                                                                <div className='flex mb-8 space-between'>
                                                                    <label className='ladiui-label pb-0'>{t('STORES.EMAIL_HOOK')}</label>
                                                                    <span style={{ fontSize: 12 }}>
                                                                        <a
                                                                            href='https://help.ladisales.vn/cai-dat-tai-khoan/phuong-thuc-thanh-toan/chuyen-khoan-qua-ngan-hang/tu-dong-xac-nhan-da-thanh-toan-cho-don-hang-chuyen-khoan-qua-ngan-hang'
                                                                            target='_blank'
                                                                        >
                                                                            Hướng dẫn{' '}
                                                                        </a>
                                                                        cài đặt chuyển tiếp email sau khi thanh toán
                                                                    </span>
                                                                </div>
                                                                <Input
                                                                    value={storeReducer.store ? storeReducer.store.email_hook : ''}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                            <button
                                                                className='ladiui btn btn-outline-primary ml-24'
                                                                onClick={() => handleCopyEmailHook(storeReducer.store.email_hook)}
                                                            >
                                                                {t('ACTIONS.COPY')}
                                                            </button>
                                                        </div>
                                                    </>
                                                )}

                                                <div className='ladiui form-group'>
                                                    <label className='ladiui-label'>Số tài khoản</label>
                                                    <Input
                                                        value={bankItem.accountNumber}
                                                        name='accountNumber'
                                                        onChange={(event) => onChangeBankDataInput(event, index)}
                                                        placeholder={'Nhập số tài khoản'}
                                                    />
                                                </div>
                                                <div className='ladiui form-group'>
                                                    <label className='ladiui-label'>Tên chủ tài khoản</label>
                                                    <Input
                                                        value={bankItem.accountName}
                                                        name='accountName'
                                                        placeholder={'Nhập tên chủ tài khoản'}
                                                        onChange={(event) => onChangeBankDataInput(event, index)}
                                                    />
                                                </div>
                                                <div className='ladiui form-group'>
                                                    <label className='ladiui-label'>Tiền tố nội dung chuyển khoản (Bắt buộc)</label>
                                                    <Input
                                                        ref={(ref) => bankRef.add(ref)}
                                                        validationName={'tiền tố nội dung chuyển khoản'}
                                                        value={bankItem.transferContentPrefix}
                                                        name='transferContentPrefix'
                                                        placeholder={'Nhập tiền tố nội dung chuyển khoản'}
                                                        onChange={(event) => onChangeBankDataInput(event, index)}
                                                        validations={{ isRequired: true }}
                                                    />
                                                </div>
                                                {!appConfig.LIST_BANK_AUTO_PAYMENT.includes(bankItem.bank.shortName) && (
                                                    <div className='ladiui form-group'>
                                                        <div className='flex space-between pb-8'>
                                                            <label className='ladiui-label pb-0'>
                                                                Nội dung chuyển khoản (Không bắt buộc)
                                                            </label>
                                                            {/* <a href="https://my.vietqr.io/vietqr/templates" target="_blank" className="ml-8">Danh sách mã tùy chỉnh</a> */}
                                                        </div>
                                                        <Input
                                                            value={bankItem.transactionContent}
                                                            name='transactionContent'
                                                            placeholder={'Nội dung chuyển khoản'}
                                                            onChange={(event) => onChangeBankDataInput(event, index)}
                                                        />
                                                    </div>
                                                )}
                                                <div className='ladiui form-group'>
                                                    <div className='flex pb-8'>
                                                        <label className='ladiui-label pb-0'>Template VietQR ID (Không bắt buộc)</label>
                                                        <a
                                                            href='https://help.ladisales.vn/cai-dat-tai-khoan/phuong-thuc-thanh-toan/chuyen-khoan-qua-ngan-hang/huong-dan-tao-template-vietqr-id'
                                                            target='_blank'
                                                            className='ml-8'
                                                        >
                                                            Hướng dẫn tạo template mẫu
                                                        </a>
                                                    </div>
                                                    <div className='flex'>
                                                        <Input
                                                            value={bankItem.template_id}
                                                            name='template_id'
                                                            placeholder={'Nhập vào Template VietQR ID của bạn'}
                                                            id='inputVietQr'
                                                            onChange={(event) => onChangeBankDataInput(event, index)}
                                                            disabled={isEditVietQR}
                                                        />
                                                        <button
                                                            className='ladiui btn btn-outline-primary ml-24'
                                                            onClick={() => {
                                                                setIsEditVietQR(!isEditVietQR);
                                                            }}
                                                        >
                                                            Chỉnh sửa
                                                        </button>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}

                                <div id='data-tooltip-guide' className='ladiui form-group'>
                                    <label className='ladiui-label'>
                                        <span className='tooltip-paymet-guide'>{t('PAYMENTS.LB_PAYMENT_GUIDE')}</span>
                                        <span
                                            className='tooltip-icon'
                                            data-tooltip={t('PAYMENTS.LB_TT_PAYMENT_GUIDE')}
                                            data-tooltip-position='right'
                                        >
                                            {' '}
                                            <i className='ladi-icon icon-c-question' />
                                        </span>
                                    </label>
                                    <textarea
                                        name='payment_guide'
                                        className='ladiui textarea'
                                        value={bankAccount.payment_guide}
                                        onChange={(event) => onChangeBankInput(event)}
                                    />
                                </div>
                                {baseHelper.isDevelopEnv() && (
                                    <div className='ladiui form-group flex'>
                                        <input
                                            id='bank_mode'
                                            type='checkbox'
                                            className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                            onChange={() => setBankMode(!bankMode)}
                                            checked={bankMode == appConfig.MODE.TEST}
                                        />
                                        <label htmlFor='bank_mode'> {t('MODE.TEST')}</label>
                                    </div>
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </Modal>
    );
}
