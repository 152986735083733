import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { I18nContext, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import produce from 'immer';
import Input from '../../../components/Input';
import { map, find } from 'lodash';

// import checkoutConfigActions from "../../../redux/futures/checkout_config/actions";
// import * as checkoutConfigTypes from "../../../redux/futures/checkout_config/types";

import pageCheckoutActions from '../../../redux/futures/page_checkout/actions';
import * as pageCheckoutTypes from '../../../redux/futures/page_checkout/types';

import domainActions from '../../../redux/futures/domain/actions';
import * as domainTypes from '../../../redux/futures/domain/types';

import fileActions from '../../../redux/futures/file/actions';
import * as fileTypes from '../../../redux/futures/file/types';

import SelectOption from '../../../components/SelectOption';
import PanelProductSeoSocial from '../../../components/PanelProductSeoSocial';

import baseHelper from '../../../helpers/BaseHelper';

import appConfig from '../../../config/app';
import config from '../../../config/config';

const CDN = 'https://w.ladicdn.com/';

const PanelSettingDomainCourse = forwardRef((props, ref) => {
    let { isShow, currentDataDomain, selectedLogo, urlPublished, selectedPath, handlePreviewPageCourse } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const checkoutConfigReducer = useSelector((state) => state.checkoutConfigReducer);
    const pageCheckoutReducer = useSelector((state) => state.pageCheckoutReducer);
    const domainReducer = useSelector((state) => state.domainReducer);

    const fileReducer = useSelector((state) => state.file);

    const courseReducer = useSelector((state) => state.course);

    const inputsRef = new Set();

    const productSeoSocialRef = React.createRef();

    const [pageCheckout, setPageCheckout] = useState({
        checkout_config_id: null,
        domain: '',
    });

    const [logo, setLogo] = useState('');

    const [urlDefault, setUrlDefault] = useState('');

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return {
                checkout_config_id: pageCheckout.checkout_config_id,
                domain: pageCheckout.domain,
                seo: productSeoSocialRef?.current ? productSeoSocialRef.current.getData() : [],
                logo: logo,
            };
        },
    }));

    useEffect(() => {
        if (currentDataDomain) {
            setPageCheckout({
                checkout_config_id: currentDataDomain.checkout_config_id || null,
                domain: currentDataDomain.domain,
            });
        }
    }, [currentDataDomain]);

    useEffect(() => {
        setUrlDefault(`https://course.ldp.store/${selectedPath}`);
    }, [selectedPath]);

    useEffect(() => {
        if (selectedLogo && selectedLogo != '') {
            setLogo(selectedLogo);
        }
    }, [selectedLogo]);

    const handleSubmitData = () => {
        props.handleSubmitData();
    };

    useEffect(() => {
        let payload = {
            search: {
                type: 'embed',
                embed_platform: 'Ladipage',
            },
        };
        dispatch(pageCheckoutActions.list(payload));
        dispatch(
            domainActions.list({
                types: 'PAGE_COURSE',
                is_verified: 1,
            })
        );
    }, []);

    useEffect(() => {
        if (fileReducer.action == fileTypes.UPLOAD_CART_LOGO) {
            if (fileReducer.status) {
                setLogo(fileReducer.cart_logo_url);
            }
        }
    }, [fileReducer]);

    useEffect(() => {
        if (pageCheckoutReducer.page_checkouts && pageCheckoutReducer.page_checkouts.length > 0) {
            // let defaultPageCheckout = pageCheckoutReducer.page_checkouts.filter((item) => item.is_default == 1);

            let defaultPageCheckout = find(pageCheckoutReducer.page_checkouts, (item) => item.is_default == appConfig.STATUS.ACTIVE);

            if (defaultPageCheckout && !pageCheckout.checkout_config_id) {
                setPageCheckout({
                    ...pageCheckout,
                    checkout_config_id: defaultPageCheckout.page_checkout_id,
                });
            }
        }
    }, [pageCheckoutReducer]);

    const handelChangeConfig = (checkoutConfigId) => {
        setPageCheckout({
            ...pageCheckout,
            checkout_config_id: checkoutConfigId,
        });
    };

    const handelChangeDomain = (domainData) => {
        setPageCheckout({
            ...pageCheckout,
            domain: domainData.domain,
        });
    };

    const fileChangedHandlerCartLogo = (event) => {
        if (event.target.files.length > 0) {
            const uploadFile = event.target.files[0];
            const form = baseHelper.getFormDataUpload([uploadFile]);
            if (form) {
                dispatch(fileActions.uploadCartLogo(form));
            }
        }
    };

    const removeCartLogo = () => {
        setLogo('');
    };

    const handleActionPreviewPageCourse = () => {
        handlePreviewPageCourse();
    };

    return (
        <div className='page-setting' style={isShow ? { display: 'block' } : { display: 'none' }}>
            <div className='block-title-action'>
                <div className='title'>
                    <h3>Thiết lập chung</h3>
                </div>
                <div className='actions flex'>
                    <button className='ladiui btn btn-outline-primary mr-24' onClick={() => handleActionPreviewPageCourse()}>
                        Xem trước
                    </button>
                    <button className='ladiui btn btn-primary' onClick={() => handleSubmitData()}>
                        Lưu trang khóa học
                    </button>
                </div>
            </div>
            <div className='setting-content-domain-course config-panel'>
                <div className=''>
                    <div className='panel'>
                        <div className='panel-line'>
                            <div className='main-description'>
                                Logo
                                <br />
                                <div className='sub-description' style={{ whiteSpace: 'nowrap' }}>
                                    Kích thước tối đa 106x32 (px)
                                    <br />
                                    (Định dạng .png, .jpg, .svg)
                                </div>
                            </div>
                            <div className=''>
                                <div className='preview-store-logo'>
                                    <div id='store-logo'>
                                        <img
                                            className='store-logo'
                                            src={logo ? `${CDN}${logo}` : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'}
                                            alt=''
                                        />
                                        <div id='store-logo-overlay'>
                                            <div className='ladiui btn-group'>
                                                <div className='ladiui dropdown'>
                                                    <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                                        <i className='ladiui icon icon-ldp-pen-edit'></i>
                                                    </button>
                                                    <ul
                                                        className='ladiui dropdown-menu custom-dropdown-menu'
                                                        style={{ minWidth: '100px', textAlign: 'left' }}
                                                    >
                                                        <li className='ladiui open-modal'>
                                                            <label htmlFor='upload-cart-logo'>Thay ảnh</label>
                                                        </li>
                                                        <li className='ladiui dropdown-item danger-text' onClick={() => removeCartLogo()}>
                                                            Xóa
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <input
                                        type='file'
                                        id='upload-cart-logo'
                                        name='file'
                                        accept='image/*'
                                        multiple={false}
                                        onChange={fileChangedHandlerCartLogo}
                                        onClick={(event) => {
                                            event.target.value = null;
                                        }}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <PanelProductSeoSocial ref={productSeoSocialRef} productInfoSeo={props.currentDataSeo || {}} isShowTitle={true} />
                    <h3 className='title mt-24'>Chọn trang thanh toán và tên miền</h3>
                    <div className='ladiui form-group'>
                        <div>
                            <label className='ladiui-label'>Chọn trang thanh toán</label>
                            <div className='flex'>
                                <SelectOption
                                    wrapperStyle={{
                                        minWidth: '250px',
                                        width: '100%',
                                        display: 'inline-block',
                                        zIndex: 999,
                                    }}
                                    innerStyle={{ width: '100%', minWidth: '100%' }}
                                    customStyle={{ padding: '0px', width: '100%', zIndex: '1000' }}
                                    wrapperClassName='select-option-checkout-config mr-24'
                                    innerClassName='ellipsis'
                                    currentKey={pageCheckout.checkout_config_id}
                                    headerItems={[
                                        {
                                            key: 'create',
                                            name: t('CHECKOUTS.ADD_PAGE_CHECKOUT_CONFIG'),
                                            icon: 'icon-c-add',
                                            className: 'prevent-hide',
                                            is_header: true,
                                        },
                                        {
                                            key: 'refresh',
                                            name: t('COMMON.REFRESH_LIST'),
                                            icon: 'icon-sync',
                                            className: 'prevent-hide',
                                            is_header: true,
                                        },
                                    ]}
                                    headerKey={'key'}
                                    headerValue={'name'}
                                    items={pageCheckoutReducer.page_checkouts || []}
                                    _key={'page_checkout_id'}
                                    _value={'name'}
                                    onSelectItem={(item) => {
                                        if (item.page_checkout_id) {
                                            handelChangeConfig(item.page_checkout_id);
                                        } else {
                                            switch (item.key) {
                                                case 'create':
                                                    window.open('/page-checkout/sdk-create');
                                                    break;
                                                case 'refresh':
                                                    let payload = {
                                                        search: {
                                                            type: 'embed',
                                                            embed_platform: 'Ladipage',
                                                        },
                                                    };
                                                    dispatch(pageCheckoutActions.list(payload));
                                                    break;
                                            }
                                        }
                                    }}
                                />
                                <a
                                    className='ladiui btn btn-outline-primary'
                                    target='_blank'
                                    href={`/page-checkout/sdk-update/${pageCheckout.checkout_config_id}`}
                                >
                                    {/* {t("PRODUCTS.LB_EDIT_CHECKOUT_CONFIG")} */}
                                    Chỉnh sửa trang thanh toán
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='ladiui form-group'>
                        <label className='ladiui-label'>Truy cập đường dẫn mặc định</label>
                        <div className='flex'>
                            <div className='link-default w100'>
                                <a>{urlDefault}</a>
                            </div>
                            <button className='ladiui btn btn-outline-primary ml-24' onClick={() => window.open(urlDefault, '_blank')}>
                                Truy cập
                            </button>
                        </div>
                    </div>
                    <div className='ladiui form-group'>
                        <label className='ladiui-label pb-0'>Hoặc</label>
                    </div>
                    <div className='ladiui form-group'>
                        <label className='ladiui-label'>Chọn tên miền trang khóa học</label>
                        <div className='flex'>
                            <SelectOption
                                wrapperStyle={{
                                    minWidth: '250px',
                                    width: '100%',
                                    display: 'inline-block',
                                    zIndex: 999,
                                }}
                                innerStyle={{ width: '100%', minWidth: '100%' }}
                                customStyle={{ padding: '0px', width: '100%' }}
                                wrapperClassName='select-option-checkout-config'
                                innerClassName='ellipsis'
                                currentValue={pageCheckout.domain}
                                headerItems={[
                                    {
                                        key: 'create',
                                        name: t('CHECKOUTS.ADD_DOMAIN_CONFIG'),
                                        icon: 'icon-c-add',
                                        className: 'prevent-hide',
                                        is_header: true,
                                    },
                                    {
                                        key: 'refresh',
                                        name: t('COMMON.REFRESH_LIST'),
                                        icon: 'icon-sync',
                                        className: 'prevent-hide',
                                        is_header: true,
                                    },
                                ]}
                                headerKey={'key'}
                                headerValue={'name'}
                                items={domainReducer.domains || []}
                                _key={'domain_id'}
                                _value={'domain'}
                                onSelectItem={(item) => {
                                    if (item.domain_id) {
                                        handelChangeDomain(item);
                                    } else {
                                        switch (item.key) {
                                            case 'create':
                                                if (baseHelper.isIframe()) {
                                                    let query_ls = {
                                                        type: 'setting-checkout',
                                                        is_new: true,
                                                    };
                                                    window.open(
                                                        `${config.BUILDER_LDP}${
                                                            appConfig.LADIPAGE_URL.SETTING_DOMAIN
                                                        }&query_ls=${JSON.stringify(query_ls)}`,
                                                        '_blank'
                                                    );
                                                    return;
                                                }

                                                window.open('/settings?type=setting-checkout&is_new=true&tab=DOMAIN');
                                                break;
                                            case 'refresh':
                                                dispatch(domainActions.list());
                                                break;
                                        }
                                    }
                                }}
                            />
                            <button
                                className='ladiui btn btn-outline-primary ml-24'
                                onClick={() => {
                                    window.open(`https://${pageCheckout.domain}`, '_blank');
                                }}
                            >
                                Truy cập
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
PanelSettingDomainCourse.propTypes = {};

export default PanelSettingDomainCourse;
