import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { I18nContext, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import PanelSettingMenuBanner from './components/PanelSettingMenuBanner';
import PanelCourseCatalog from './components/PanelCourseCatalog';
import PanelSettingFooterPage from './components/PanelSettingFooterPage';
import PanelSettingDomainCourse from './components/PanelSettingDomainCourse';
// import PanelStudentManagement from "./components/PanelStudentManagement";

import pageCheckoutActions from '../../redux/futures/page_checkout/actions';

import * as pageCheckoutTypes from '../../redux/futures/page_checkout/types';

import LoadingScene from '../../components/LoadingScene';

import ValidatorHelper from '../../helpers/ValidatorHelper';
import { compact, map, find, debounce, findIndex, includes } from 'lodash';

import baseHelper from '../../helpers/BaseHelper';

const PageCourse = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    let hash = window.location.hash;
    if (!hash) {
        hash = 'DOMAIN_SETTING';
    } else {
        hash = hash.replace('#', '');
    }

    const fileReducer = useSelector((state) => state.file);

    const pageCheckoutReducer = useSelector((state) => state.pageCheckoutReducer);

    const [tabSelected, setTabSelected] = useState(hash);

    const [currentDataMenu, setCurrentDataMenu] = useState({});
    const [currentDataDomain, setCurrentDataDomain] = useState({});
    const [pageCheckoutId, setPageCheckoutId] = useState(null);
    const [currentCourseCatalog, setCurrentCourseCatalog] = useState({});
    const [currentDataSeo, setCurrentDataSeo] = useState({});
    const [currentDataFooter, setCurrentDataFooter] = useState({});
    const [isUpdateCourse, setIsUpdateCourse] = useState(false);
    const [selectedPath, setSelectedPath] = useState('');

    const [listBanners, setListBanners] = useState([]);

    const [suggestedCategories, setSuggestedCategories] = useState([]);

    const [selectedLogo, setSelectedLogo] = useState('');

    const [isPreviewPageCourse, setIsPreviewPageCourse] = useState(false);

    const inputsRef = new Set();

    const panelSettingMenuBannerRef = React.createRef();
    const panelCourseCatalogRef = React.createRef();
    const panelSettingFooterPageRef = React.createRef();
    const productSeoSocialRef = React.createRef();
    const panelDomainCourseRef = React.createRef();
    const panelStudentManagementRef = React.createRef();

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return {};
        },
    }));

    useEffect(() => {
        dispatch(pageCheckoutActions.pageCourseShow());
    }, []);

    useEffect(() => {
        if (pageCheckoutReducer.action == pageCheckoutTypes.PAGE_COUSE_SHOW) {
            if (pageCheckoutReducer.status) {
                if (pageCheckoutReducer.dataPageCheckout) {
                    let pageCheckout = pageCheckoutReducer.dataPageCheckout;
                    if (pageCheckout.page_checkout_id) {
                        setPageCheckoutId(pageCheckout.page_checkout_id);
                    }
                    setCurrentDataMenu(pageCheckout.config_course.menu_banner);
                    setListBanners(pageCheckout.config_course.menu_banner.banners || []);

                    setSuggestedCategories(pageCheckout.config_course.menu_banner.suggested_categories || []);

                    setSelectedLogo(pageCheckout.config_course.menu_banner.logo || []);

                    setCurrentDataFooter(pageCheckout.config_course.footer);

                    setCurrentDataDomain({
                        checkout_config_id: pageCheckout.checkout_config_id,
                        domain: pageCheckout.domain,
                    });

                    setCurrentDataSeo(pageCheckout.seo);

                    setCurrentCourseCatalog({
                        selectedCourses: pageCheckout.featured_products,
                        config_category: pageCheckout.categories,
                    });
                    setSelectedPath(pageCheckout.path || '');
                    if (isPreviewPageCourse) {
                        if (pageCheckout.domain && !pageCheckout.domain.includes('course.ldp.store')) {
                            setTimeout(() => {
                                window.open(`https://${pageCheckout.domain}`, '_blank', 'noopener,noreferrer');
                            }, 2000);
                        } else {
                            setTimeout(() => {
                                window.open(`https://course.ldp.store/${pageCheckout.path}`, '_blank', 'noopener,noreferrer');
                            }, 2000);
                        }
                    }
                } else {
                    history.push('/page-course/welcome');
                    // window.location.replace('/page-course/welcome');
                }
            }
        }
        if (pageCheckoutReducer.action == pageCheckoutTypes.CREATE_PAGE_CHECKOUT) {
            if (pageCheckoutReducer.status) {
                window.LadiUI.toastCustom('success', '', pageCheckoutReducer.message);
                dispatch(pageCheckoutActions.pageCourseShow());
            } else {
                window.LadiUI.showErrorMessage('Thông báo', pageCheckoutReducer.message, 'OK');
            }
        }
        if (pageCheckoutReducer.action == pageCheckoutTypes.UPDATE_PAGE_CHECKOUT) {
            if (pageCheckoutReducer.status) {
                window.LadiUI.toastCustom('success', '', pageCheckoutReducer.message);
                dispatch(pageCheckoutActions.pageCourseShow());
            } else {
                window.LadiUI.showErrorMessage('Thông báo', pageCheckoutReducer.message, 'OK');
            }
        }
    }, [pageCheckoutReducer]);

    const getPayloadCategories = async (menuIds) => {
        let Menus = [];
        for await (let menu of menuIds) {
            let payloadItem = {
                product_category_id: menu.product_category_id,
                children: menu.children && menu.children.length > 0 ? await getPayloadCategories(menu.children) : [],
            };
            Menus.push(payloadItem);
        }

        return Menus;
    };

    const submitData = async () => {
        let errors = [];
        const _inputsRef = compact(Array.from(inputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);

            return;
        }

        const dataMenuRef = panelSettingMenuBannerRef.current.getData();
        const dataCourseCatalogRef = panelCourseCatalogRef.current.getData();
        const dataFooterRef = panelSettingFooterPageRef.current.getData();
        const dataDomainSetting = panelDomainCourseRef.current.getData();

        let payloadCategoryMenuIds = [];

        if (dataMenuRef.menu_category_ids && dataMenuRef.menu_category_ids.length > 0) {
            payloadCategoryMenuIds = await getPayloadCategories(dataMenuRef.menu_category_ids);
        }

        let payloadSubmitData = {
            page_checkout: {
                checkout_config_id: dataDomainSetting?.checkout_config_id ? dataDomainSetting.checkout_config_id : null,
                domain: dataDomainSetting?.domain ? dataDomainSetting.domain : '',
                types: 'Course',
                name: 'Trang khóa học',
                featured_product_ids: dataCourseCatalogRef?.featured_product_ids ? dataCourseCatalogRef.featured_product_ids : [],
                config_category: dataCourseCatalogRef?.config_category ? dataCourseCatalogRef.config_category : [],
                seo: dataDomainSetting?.seo ? dataDomainSetting.seo : [],
                config_course: {
                    menu_banner: {
                        ...dataMenuRef,
                        menu_category_ids: payloadCategoryMenuIds || [],
                        logo: dataDomainSetting?.logo ? dataDomainSetting.logo : '',
                        banners: dataCourseCatalogRef?.banners ? dataCourseCatalogRef.banners : [],
                        suggested_category_ids: dataCourseCatalogRef?.suggested_category_ids
                            ? dataCourseCatalogRef.suggested_category_ids
                            : [],
                    },
                    footer: {
                        ...dataFooterRef,
                    },
                },
            },
        };
        if (pageCheckoutId) {
            payloadSubmitData.page_checkout.page_checkout_id = pageCheckoutId;
            dispatch(pageCheckoutActions.update(payloadSubmitData));
        } else {
            dispatch(pageCheckoutActions.create(payloadSubmitData));
        }
    };

    const handleSubmitData = () => {
        setIsPreviewPageCourse(false);
        submitData();
    };

    const handlePreviewPageCourse = () => {
        submitData();
        setIsPreviewPageCourse(true);
    };

    const handleSetTabSelected = (value) => {
        window.location.hash = `#${value}`;
        setTabSelected(value);
    };

    const isLoadingSubmitCourse =
        pageCheckoutReducer.loading &&
        includes([pageCheckoutTypes.CREATE_PAGE_CHECKOUT, pageCheckoutTypes.UPDATE_PAGE_CHECKOUT], pageCheckoutReducer.waiting);

    return (
        <div className='setting-page setting-page-general page-setting-course'>
            {isLoadingSubmitCourse && <LoadingScene blur={true} />}
            <div className={`setting-content`}>
                <ul className='mt-24'>
                    <li
                        className={`${tabSelected == 'DOMAIN_SETTING' ? 'ladiui setting-item active' : 'ladiui setting-item'}`}
                        onClick={() => handleSetTabSelected('DOMAIN_SETTING')}
                    >
                        <div className='text'>
                            <p className='title'>Cài đặt chung</p>
                        </div>
                    </li>
                    <li
                        className={`${tabSelected == 'MENU_BANNER' ? 'ladiui setting-item active' : 'ladiui setting-item'}`}
                        onClick={() => handleSetTabSelected('MENU_BANNER')}
                    >
                        <div className='text'>
                            <p className='title'>Menu và màu sắc</p>
                        </div>
                    </li>
                    <li
                        className={`${tabSelected == 'COURSE_CATALOG' ? 'ladiui setting-item active' : 'ladiui setting-item'}`}
                        onClick={() => handleSetTabSelected('COURSE_CATALOG')}
                    >
                        <div className='text'>
                            <p className='title'>Trang chủ</p>
                        </div>
                    </li>
                    <li
                        className={`${tabSelected == 'FOOTER_PAGE' ? 'ladiui setting-item active' : 'ladiui setting-item'}`}
                        onClick={() => handleSetTabSelected('FOOTER_PAGE')}
                    >
                        <div className='text'>
                            <p className='title'>Chân trang</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div className='right-content'>
                <PanelSettingMenuBanner
                    isShow={tabSelected == 'MENU_BANNER'}
                    handleSubmitData={handleSubmitData}
                    currentDataMenu={currentDataMenu || {}}
                    ref={panelSettingMenuBannerRef}
                    handlePreviewPageCourse={handlePreviewPageCourse}
                />
                <PanelCourseCatalog
                    isShow={tabSelected == 'COURSE_CATALOG'}
                    currentCourseCatalog={currentCourseCatalog || {}}
                    handleSubmitData={handleSubmitData}
                    ref={panelCourseCatalogRef}
                    selectedBanners={listBanners || []}
                    currentSuggestedCategories={suggestedCategories || []}
                    handlePreviewPageCourse={handlePreviewPageCourse}
                />
                <PanelSettingFooterPage
                    isShow={tabSelected == 'FOOTER_PAGE'}
                    currentDataFooter={currentDataFooter || {}}
                    handleSubmitData={handleSubmitData}
                    ref={panelSettingFooterPageRef}
                    handlePreviewPageCourse={handlePreviewPageCourse}
                />
                <PanelSettingDomainCourse
                    isShow={tabSelected == 'DOMAIN_SETTING'}
                    currentDataDomain={currentDataDomain || {}}
                    currentDataSeo={currentDataSeo || {}}
                    selectedLogo={selectedLogo}
                    handleSubmitData={handleSubmitData}
                    ref={panelDomainCourseRef}
                    selectedPath={selectedPath}
                    handlePreviewPageCourse={handlePreviewPageCourse}
                />
                {/* <PanelStudentManagement
          isShow={tabSelected == "STUDENT_MANAGEMENT"}
          handleSubmitData={() => submitData()}
          currentDataMenu={[]}
          ref={panelStudentManagementRef}
        /> */}
            </div>
        </div>
    );
});
PageCourse.propTypes = {
    titlePage: PropTypes.string,
    descriptionPage: PropTypes.string,
    img_publish: PropTypes.string,
    favicon: PropTypes.string,
};

export default PageCourse;
